body {
  margin: 0;
  font-family: "Public Sans";
}

.app {

  color: #585858;
  font-size: 14px;
  line-height: 18px;
  text-align: left;


}

.css-i4bv87-MuiSvgIcon-root {
  height: 35px !important;
  width: 35px !important;

}

.active-menu-color {
  color: white !important;

}

.inactive-menu-color {
  color: #858d95 !important;

}


.css-1wvake5 {
  background-color: #ffffff;

}

.css-dip3t8 {
  /* position: fixed !important; */
  background-color: #ffffff !important;
  overflow: visible !important;
}

.sidebar-container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1000;
  overflow: hidden;
  overflow-y: auto;

  width: 300px;
  background-color: #ffffff;
  transition: 0.3s ease-in-out;
}

.css-1sdt58x {
  border: none !important;
}


.sidebar-container.collapsed {
  width: 55px;
  transition: 0.3s ease-in-out;

}

aside.ps-sidebar-root.ps-collapsed.app.css-s4s0yg {
  transition: 0.3s ease-in-out;
}

.sidebar_logo_collasaped {
  margin-left: -5px;
}



.main-web-content.collapsed {
  margin-left: 55px;
  transition: 0.3s ease-in-out;
}

.main-web-content {
  margin-left: 300px;
  /* background: red !important; */
  transition: 0.3s ease-in-out;
}

.css-1sdt58x.ps-collapsed {
  width: 63px !important;
  min-width: 63px !important;

}

.ps-collapsed {
  transition: 0.3s ease-in-out;
}


.css-1097eso {
  height: 0px !important;
}



.sidebar_logo {
  width: 75%;
  height: auto;
  object-fit: contain;
  cursor: default !important;
  padding-bottom: 5px;

}

.ps-menu-icon img {
  width: 20px;
  height: 20px;
}


.css-honxw6 {

  width: 8px !important;
  height: 8px !important;
}

.css-jn69v9 {
  width: 8px !important;
  height: 8px !important;
}



.css-1tqrhto>.ps-menu-button {
  padding-left: 20px !important;
}

.css-1tqrhto:hover {
  background-color: #f3f3f3 !important;
}

li.ps-menuitem-root.menu-no-hover.css-1t8x7v1 a {
  height: 120px;
  transition: 1s;
  /* border-bottom: 1px solid #f3f3f3; */
}

li.ps-menuitem-root.logo-img-icon a {
  height: 56px !important;
  transition: 1s;
}


.css-wx7wi4 {
  margin-right: 9px !important;
}



.css-1t8x7v1>.ps-menu-button:hover {
  /* background: none !important; */
  /* border-bottom: 1px solid #f3f3f3; */

}

.css-1t8x7v1>.ps-menu-button {
  padding-left: 13px !important;
}


.css-16jesut {
  border-bottom: 1px solid #f3f3f3;

}


.css-16jesut a span #fi_16782261 {
  /* margin-right: 11px; */
}

span.ps-menu-icon.css-wx7wi4 #fi_2923845 {
  height: 25px;
  width: 21px;
}

.css-16jesut>.ps-menu-button {

  /* margin: 20px 0px; */
  padding: 0px 5 !important;
  padding-right: 5px;
  color: #585858 !important;
  /* font-weight: 700 !important; */
  /* opacity: 0.8 !important; */
  /* padding-left: 10px !important; */

}

a.ps-menu-button {
  padding-left: 13px !important;
}

.ps-submenu-content.ps-open.css-wlra1l {
  inset: 0px auto auto -10px !important;
   width: 250px;

}

/* .ps-submenu-content.css-wlra1l
{
  transform: translateX(px,274px) !important;
} */

.sidebar-dots {
  width: 8px;
  height: 2px;
  background-color: #585858;
}

.sidebar-dots-squr {
  width: 5px;
  height: 5px;
  background-color: #585858;
}

.css-1tqrhto>.ps-menu-button {
  padding-right: 0px !important;
  margin: 0px 20px !important;  
}

.css-1ffbfs>.ps-menu-button {
  padding-right: 0 !important;
}

span.ps-submenu-expand-icon.css-1cuxlhl span {
  margin-right: 18px;
}

.css-1t8x7v1 {
  border-bottom: 1px solid #f3f3f3;
}

.ps-menuitem-root
{
  margin-bottom: 0;
}


li.ps-menuitem-root.deactive-menu.css-1t8x7v1:hover {
  background: #f3f3f3;
}


/* ********submenu under menu style ******* */

.css-1ffbfs>.ps-menu-button {
  padding-left: 40px !important;
}

.css-x3jz15>.ps-menu-button {
  margin-left: 60px;
}

/* ****sidebar-active**** */


.active-menu {

  color: #000000 !important;
}

.ps-menu-root *
{
  transition: none;

}


li.ps-menuitem-root.deactive-menu.css-1tqrhto a span .sidebar-dots {
  background-color: #585858;
}

li.ps-menuitem-root.active-menu.css-1tqrhto a span div {
  background: #000000 !important;
}


/* *****hendle orex active active menu css ******* */


li.active-menu-item {
  background: #f3f3f3;

}


.active-parent-menu a {
  border-left: 4px solid #0B4471;
}

.active-parent-menu a .ps-submenu-expand-icon {
  color: #0B4471;
}

.deactive-sub-menu a .ps-submenu-expand-icon {
  color: #585858;
}

.active-sub-menu a,
.active-menu-item a,
.deactive-parent-menu a,
.deactive-sub-menu a,
.deactive-menu-item a {
  border-left: none;
}


.active-parent-menu .ps-menu-button .ps-menu-label,
.active-sub-menu .ps-menu-button .ps-menu-label,
.active-menu-item .ps-menu-button .ps-menu-label {
  color: #000;
}

.deactive-parent-menu .ps-menu-button .ps-menu-label,
.deactive-sub-menu .ps-menu-button .ps-menu-label,
.deactive-menu-item .ps-menu-button .ps-menu-label {
  color: #585858;
}


li.ps-menuitem-root.css-x3jz15:hover {
  background: #f3f3f3;
}

.deactive-menu {
  color: #585858;
}

.deactive-menu .sidebar-dots {
  color: #585858;
}

li.ps-menuitem-root.ps-submenu-root.ps-open.active-menu.css-16jesut div ul li a .sidebar-dots {
  background-color: #000000;
}

.css-16jesut>.ps-menu-button {
  margin-left: 1px;
  padding-right: 0 !important;
}

.active-menu svg path {
  fill: #0B4471;
}

.deactive-menu svg path {
  fill: #585858 !important;

}


.active-parent-menu .ps-menu-button span svg path,
.active-sub-menu .ps-menu-button span svg path,
.active-menu-item .ps-menu-button span svg path {
  fill: #0B4471 !important;
}

.deactive-parent-menu .ps-menu-button span svg path,
.deactive-sub-menu .ps-menu-button span svg path,
.deactive-menu-item .ps-menu-button span svg path {
  fill: #585858 !important;
}


.menu-analitics-active .ps-menu-button:hover {

  background-color: #1a6097 !important;

}

.css-1sdt58x.ps-broken {
  left: 0 !important;
  transition: 0.3s ease-in-out;
}


.content {

  flex: 1;
  padding: 0px !important;
  background-color: #f5f5f9;
  z-index: 5;
  height: max-content;
  width: calc(100% - 300px);

}



.pro-img {
  height: 41px;
  width: 41px;
}


.cng-color {
  color: #a7acb2;
}

table tr {
  line-height: 50px;
}

.color85 {
  color: #858d95 !important;
}



element .style {
  display: none !important;
}

.menu-no-hover a {
  cursor: default !important;
  text-align: center!important;
  background-color: #fff !important;
}

/* .menu-no-hover:focus-visible,
.ps-menu-button:focus-visible {
  display: contents;
} */

.orex_sidebar_menu:focus-visible,
.orex_sidebar_menu li a:focus-visible {
  outline: none;
}



.logo-img-icon
/* ******navbar****** */

.nav-input-search:focus {

  border-color: inherit !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: none !important;
}

.nav-account-img img {
  cursor: pointer;
  height: 40px;
  width: 40px;

}


.fa-magnifying-glass {
  color: #b6becc;
}


.fa-bell {
  cursor: pointer !important;
}

.dropdown-toggle::after {
  display: none !important;
}

.dropdown-menu {
  transform: translate(-75%, 32%) !important;
  filter: drop-shadow(0px 0px 4px #d4d4d4);
  border: none;
  padding: 10px 5px;

}

.dropdown-menu h6,
.dropdown-menu p {
  font-size: 14px;
  color: #000;
  text-wrap: wrap;
  overflow-wrap: break-word;
}

.dropdown-item:hover {
  background-color: #f3f3f3;
}

.dropdown-menu li {
  cursor: pointer;

}

.dropdown-menu-border {
  border-bottom: 0.1px solid #d4d4d4;


}


.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}



.sub-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: 13px 0px;
  position: relative;
}





.sub-header h3 {
  font-size: 23px;
  color: #000000;
  font-weight: 700;
}

.sub-tital {
  font-size: 22px;
  color: #000000;
  font-weight: 500;
  margin: 10px 30px 0px;

}


.nav-pills .nav-link {
  background: #fff;
  border: 0.8px solid #cecece;
  margin: 0px 6px 0px;
  font-weight: 600;
  padding: 11px 20px;
  align-items: center;
}

.nav-pills .nav-link:hover {
  background: #e3e3e3;

}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  border: none !important;
  background: #0B4471;
  border: 0.8px solid #cecece !important;
}


.nav-item a {
  text-decoration: none !important;

}


.nav {
  --bs-nav-link-color: black !important;
}

.nav-pills {
  --bs-nav-pills-link-active-bg: #1a6097 !important;
  --bs-nav-pills-link-active-color: white !important;
}


.active svg path {

  fill: #ffffff;

}


/* tablle *******88 */


.rdt_TableHeadRow {
  background-color: #e5e7ed !important;
  border-radius: 0px;
}


@media(max-width:1500px) {


  .nav-pills .nav-link {
    border: 0.8px solid #cecece;
    margin: 0px 3px 10px;
  }

}


@media(max-width:800px) {


  .img-upload {
    justify-content: center;
    text-align: center;
  }


}