@font-face {
  font-family: 'futurabold';
  src: url('../fonts/unicode.futurab-webfont.eot');
  src: url('../fonts/unicode.futurab-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/unicode.futurab-webfont.woff2') format('woff2'),
    url('../fonts/unicode.futurab-webfont.woff') format('woff'),
    url('../fonts/unicode.futurab-webfont.ttf') format('truetype'),
    url('../fonts/unicode.futurab-webfont.svg#futurabold') format('svg');
  font-weight: normal;
  font-style: normal;

}

@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

:root {
  --primary-color: #0b426d;
  --secondry-color: #df0024;
  --white: #ffffff;
  --black: #000000;
  --light-primary: #2e9bf0;
  --light-primary-1: #b2c9da;
  --light-primary-2: #529ed2;
  --light-white-gray: #f7f7f7;
  --dark-gray: #3e3e3e;
  --gray-text: #666666;
  --usp-border: #ebebeb;

  --unit8: 8px;
  --unit16: 16px;
  --unit24: 24px;
  --unit32: 32px;

  --brd1: #e6e6e6;

  --fontFuturaBold: 'futurabold';
  --fontInter: "Inter", serif;
}

/* .outer_pages * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-family: var(--fontInter);
  transition: all 0.25s linear 0.25s;
} */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-family: var(--fontInter);
  transition: all 0.15s linear 0.15s;
}

.outer_pages *:before,
.outer_pages *:after {
  transition: all 0.15s linear 0.15s;
}

.outer-pages *:hover,
.outer-pages *:before:hover,
.outer-pages *:after:hover {
  transition: all 0.15s linear 0.15s;
}

.outer-pages img {
  max-width: 100%;
  height: auto;
}

a {
  color: var(--secondry-color);
}

a:hover,
a:focus {
  text-decoration: none;
  color: var(--secondry-color);
}

ul,
li {
  list-style: inside none;
  padding: 0;
  margin-bottom: 10px;
}

.outer-pages p {
  font-size: var(--unit16);
  margin-bottom: 25px;
  line-height: 1.5;
}

.outer-pages hr {
  width: 100%;
  background: rgba(0, 0, 0, 0.10);
  display: flex;
  height: 1px;
  content: "";
  margin-bottom: 15px !important;
}

.sprite {
  background: url(../imgs/sprite.svg) no-repeat;
}

.outer-pages p.note {
  font-size: 14px;
}

.outer-pages .p-r-0 {
  padding-right: 0;
}

.outer-pages .m-t-10 {
  margin-top: 10px;
}

.outer-pages .m-t-15 {
  margin-top: 15px;
}

.outer-pages .m-b-0 {
  margin-bottom: 0;
}

.outer-pages .m-b-5 {
  margin-bottom: 5px;
}

.outer-pages .m-b-15 {
  margin-bottom: 15px;
}

.outer-pages .m-t-50 {
  margin-top: 50px;
}

.outer-pages .fl {
  float: left;
}

.outer-pages .fr {
  float: right;
}

.outer-pages iframe {
  width: 100%;
  height: 100%;
  border: 0;
}

.outer-pages .primary-btn:hover {
  background: var(--secondry-color);
  color: var(--white);
}

.outer-pages .border-btn:hover {
  background: var(--secondry-color);
  color: var(--white);
}

.outer-pages .btn:disabled {
  background: var(--gray-text) !important;
  color: var(--black) !important;
  opacity: 0.5;
}

.outer-pages .Drop-down-menu__placeholder {
  opacity: 0.5;
}

.para-head-text-align .section-head {
  border-bottom: 0;
}

.para-head-text-align .section-head p {
  padding-left: 0;
}

.para-head-text-align .section-head:after {
  content: "";
  height: 1px;
  width: calc(100% - 30px);
  position: absolute;
  right: 0;
  bottom: -29px;
  display: inline-block;
  background: #f0f0f0;
}

.para-head-text-align p {
  padding-left: 30px;
}

.para-head-text-align .icon-text p {
  padding-left: 0;
}

/* form control style START */
.outer-pages [class*="placeholder"] {
  opacity: 0.4;
}

.outer-pages .invalid-feedback {
  position: absolute;
}

.form-control {
  padding: 8px 10px;
  border-radius: var(--unit8);
  border-color: #ebebeb;
}

form .dd-normal .dd-pad span+div {
  padding: 6px 5px;
  max-height: inherit;
  height: inherit !important;
  border-color: #ebebeb;
}

.form-label.required:after,
.field-required:after {
  content: " *";
  color: red;
}


/* form control style END */


/* button style START */
.outer-pages .btn {
  cursor: pointer;
  line-height: 1.25;
  border: 1px solid transparent;
}

.outer-pages .primary-btn {
  background: var(--primary-color);
  color: var(--white);
  border-radius: var(--unit8);
  padding: 10px 15px;
  font-size: 16px;
}

.outer-pages .primary-btn:hover {
  background: none;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.outer-pages .secondry-btn {
  background: var(--secondry-color);
  color: var(--white);
  border-radius: var(--unit8);
  padding: 10px 15px;
  font-size: 16px;
}

.outer-pages .secondry-btn:hover {
  background: none;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.outer-pages .border-btn {
  background: var(white);
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-radius: var(--unit8);
  padding: 10px 15px;
  font-size: 16px;
}

.outer-pages .border-btn:hover {
  background: var(--primary-color);
  color: var(--white);
}


.button-cancell {
  background: var(--white);
  color: #8b8b8b;
  border: 1px solid #8b8b8b;
  border-radius: var(--unit8);
  /* padding: 10px 15px;
  font-size: 16px; */
}

.button-cancell:hover {
  background: #8b8b8b;
  color: var(--white);

}

/* button style END */

/* inner page style START */
.benefits.page footer .footer-about p,
.services.page footer .footer-about p {
  padding-left: 0;
}

.benefits.page footer .decorative,
.services.page footer .decorative {
  display: none;
}

.benefits.page .content-wrape,
.services.page .content-wrape {
  margin-bottom: 100px;
}

.benefits.page .section-head,
.services.page .section-head {
  /* border-bottom: 1px solid var(--light-white-gray); */
  margin-bottom: 55px;
}

.benefits.page .section-head .head,
.services.page .section-head .head {
  margin-bottom: 5px;
}

.benefits .img-wrape {
  margin-bottom: 50px;
}

.benefits .img-wrape .section-head {
  display: none;
}

/* inner page style END */


/* header style START */
header {
  border-top: var(--unit8) solid var(--primary-color);
  position: relative;
  display: flex;
  z-index: 9;
  flex-wrap: wrap;
}

header .brand-wrape {
  position: absolute;
  left: 0;
  top: 0;
  width: 327px;
  height: 140px;
  border-bottom-left-radius: calc(var(--unit8) * 2);
  border-bottom-right-radius: calc(var(--unit8) * 5);
  background: #e3e3e3;
  border: 1px solid var(--white);
  z-index: 3;
}

header .brand-wrape .brand {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--white);
  border-bottom-left-radius: calc(var(--unit8) * 2);
  border-bottom-right-radius: calc(var(--unit8) * 7);
  box-shadow: 5px 22px 32px rgba(0, 0, 0, 0.26);
}

header .brand-wrape .brand img {
  max-width: 253px;
}

header .up-header,
header .down-header {
  width: 100%;
  display: flex;
}

header .up-header {
  width: 100%;
  background: var(--white);
  display: flex;
  justify-content: right;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.16);
  position: relative;
  z-index: 2;
  padding: 8px 30px 8px 0;
}

.google-translate-container {
  border: 1px solid var(--brd1);
  border-radius: 8px;
  padding: 1px 0px;
}

header .up-header .PhoneInputInput {
  display: none;
}

header .up-header ul {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

header .up-header li {
  padding-left: 10px;
  margin-left: 10px;
  border-left: 1px solid var(--brd1);
  margin-bottom: 0;
  font-size: 14px;
}

header .up-header li:first-child {
  border: 0;
}

header .up-header .signup-btn {
  background: var(--secondry-color);
  padding: 16px;
  border-radius: var(--unit8);
  color: var(--white);
}

header .down-header {
  width: calc(100% - 30px);
  background: var(--white);
  display: flex;
  justify-content: space-between;
  padding-left: 365px;
  position: relative;
  z-index: 1;
  border-bottom-right-radius: 16px;
}

header .down-header nav {
  border-bottom-right-radius: 33px;
  background: var(--white);
  width: 100%;
  position: relative;
  z-index: 2;
}

header ul {
  display: flex;
  margin-bottom: 0;
}

header li {
  margin-bottom: 0;
  position: relative;
}

header li ul {
  flex-wrap: wrap;
  position: absolute;
  left: 0;
  top: 90%;
  background: rgba(255, 255, 255, 0.88);
  padding: 15px;
  border-radius: var(--unit16);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border: 1px solid var(--white);
  display: none;
}

header li li {
  width: 100%;
  white-space: nowrap;
  border-bottom: 1px solid var(--brd1);
}

header li li:last-child {
  border-bottom: 0;
}

header .down-header .menu-wrape li li a.nav-link {
  margin: 0;
  padding: 15px 15px;
  border-radius: 8px;
}

header .down-header .menu-wrape li li a.nav-link:before {
  display: none;
}

header .down-header .menu-wrape li a.nav-link:hover+ul {
  display: block;
}

header .down-header .menu-wrape li a.nav-link+ul:hover {
  display: block;
}

header .down-header .find-btn {
  border-bottom-right-radius: 16px;
  background: var(--secondry-color);
  position: absolute;
  right: 0;
  color: var(--white);
  font-size: 15.5px;
  font-weight: 700;
  text-transform: uppercase;
  width: 270px;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 21px;
  padding-left: 51px;
  text-align: end;
}

header .down-header .find-btn:hover {
  background: var(--primary-color);
}

header .down-header .menu-wrape {
  width: calc(100% - 220px);
  border-bottom-right-radius: 32px;
}

header .down-header .menu-wrape a.nav-link {
  color: var(--black);
  font-weight: 500;
  font-size: 16px;
  line-height: 1.15;
  position: relative;
  padding: 10px 20px 10px 32px;
  margin-left: 10px;
  border-radius: var(--unit24);
}

header .down-header .menu-wrape a.nav-link:before {
  content: "";
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: calc(var(--unit8) / 2);
  display: inline-block;
  left: 18px;
  top: 48%;
  background: #bdbdbd;
}

header .down-header .menu-wrape a.nav-link:hover,
header .down-header .menu-wrape a.nav-link.active {
  background: var(--primary-color);
  color: var(--white);
}

header .down-header .menu-wrape a.nav-link:hover::before,
header .down-header .menu-wrape a.nav-link.active::before {
  background: var(--white);
}

header .Drop-down-menu__control {
  border: 0 !important;
}

/* header style END */

/* nav bar style START */
/* nav {
  display: flex;
} */

/* nav bar style END */

/* section Head style START */
.section-head {
  padding-left: 30px;
  position: relative;
  margin-bottom: 60px;
}

.section-head:before {
  content: "";
  width: var(--unit8);
  height: var(--unit8);
  position: absolute;
  left: 15px;
  top: 8px;
  border: 1px solid var(--secondry-color);
  display: inline-block;
  border-radius: calc(var(--unit8) / 2);
}

.section-head .head {
  width: 100%;
  display: block;
  margin-bottom: 30px;
}

.section-head .head h2 {
  text-transform: uppercase;
  font-size: 50px;
  color: var(--secondry-color);
  margin-bottom: 5px;
  font-weight: 800;
  font-family: var(--fontFuturaBold);
}

.section-head .head h2 span {
  color: var(--primary-color);
  font-family: var(--fontFuturaBold);
}

.section-head .head p {
  text-transform: uppercase;
  font-size: var(--unit16);
  color: var(--gray-text);
  margin-bottom: 0;
}

.section-head.dark .head {
  margin-bottom: 10px;
}

.section-head.dark .head h2 {
  color: var(--white);
}

.section-head.dark .head h2 span {
  color: var(--light-primary);
}

.section-head.dark:before {
  border-color: var(--white);
}

.section-head.dark p {
  color: var(--light-primary-2);
  line-height: 1.5;
}

.section-head.dark p.text {
  color: var(--white);
}

.section-head.center {
  text-align: center;
  max-width: 62%;
  padding: 0;
  margin: 0 auto;
}

.section-head.center::before {
  display: none;
}

.section-head.center .head p::before {
  content: "";
  width: var(--unit8);
  height: var(--unit8);
  border: 1px solid var(--secondry-color);
  display: inline-block;
  border-radius: calc(var(--unit8) / 2);
  position: relative;
  left: -5px;
  top: -2px;
}

/* section Head style END */

/* our service page Style START */
.our-services-page .section-service {
  position: relative;
  margin-top: 75px;
  margin-bottom: 75px;
}

.our-services-page .section-service.reverse {
  flex-wrap: wrap-reverse;
}

.our-services-page .section-service:after,
.our-services-page .section-service.reverse:before {
  content: "";
  display: inline-block;
  width: 100%;
  background: #efefef;
  height: 1px;
}

.our-services-page .section-service.reverse::after {
  display: none;
}

.our-services-page .section-service .img-wrape {
  margin-bottom: 80px;
}

.our-services-page .section-service:last-child::after {
  display: none;
}

.our-services-page .section-service .img-wrape .section-head {
  display: none;
}

.our-services-page .small-service {
  background: var(--white);
  border-radius: var(--unit8);
  box-shadow: 3px 6px 15px rgba(0, 0, 0, 0.10);
  padding: 15px 10px 15px 100px;
  font-size: 28px;
  text-transform: uppercase;
  margin-bottom: 30px;
  position: relative;
  min-height: 100px;
  display: flex;
  align-items: center;
  ;
}

.our-services-page .small-service i {
  width: 60px;
  height: 60px;
  background-color: #f9f9f9;
  border-radius: 4px;
  position: absolute;
  left: 15px;
  top: 20px;
}

.our-services-page .small-service.plan i {
  background-position: -370px -535px;
}

.our-services-page .small-service.globally i {
  background-position: -547px -445px;
}

.our-services-page .small-service.cost i {
  background-position: -240px -572px;
}

.our-services-page .small-service.export-import i {
  background-position: -306px -574px;
}

.our-services-page .small-service.customize i {
  background-position: -372px -535px;
}

.our-services-page .small-service.analysis i {
  background-position: -488px -450px;
}

/* our service page Style END */


/* find buyer style START */
.find-buyer-wrape {
  position: relative;
  width: 100%;
}

.find-buyer-wrape .img-wrape {
  position: relative;
  padding: 30px;
}

.find-buyer-wrape .img-wrape .buble-lable {
  background: var(--secondry-color);
  width: 168px;
  height: 168px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 30px 20px 20px;
  position: absolute;
  right: 30px;
}

.find-buyer-wrape .img-wrape .buble-lable h5 {
  font-size: 56px;
  font-weight: 700;
  margin-bottom: 10px;
  color: var(--white);
  line-height: 1;
  text-align: center;
}

.find-buyer-wrape .img-wrape .buble-lable p {
  font-size: var(--unit16);
  color: var(--white);
  line-height: 1.15;
  text-align: center;
}

/* find buyer style END */


/* USP box style START */
.usp-wrape .usp {
  border: 1px solid var(--usp-border);
  border-radius: calc((var(--unit8)) - 2px);
  box-shadow: 5px 8px 24px rgba(0, 0, 0, 0.10);
  margin-bottom: 30px;
  padding: 20px 20px 20px 80px;
  position: relative;
}

.usp-wrape .usp.one {
  border-bottom-right-radius: calc(var(--unit8) * 2);
}

.usp-wrape .usp.two {
  border-bottom-left-radius: calc(var(--unit8) * 2);
}

.usp-wrape .usp.three {
  border-top-right-radius: calc(var(--unit8) * 2);
}

.usp-wrape .usp.four {
  border-top-left-radius: calc(var(--unit8) * 2);
}

.usp-wrape .usp i.sprite {
  position: absolute;
  left: 20px;
  top: 29px;
  display: inline-block;
}

.usp-wrape .usp i.sprite.search {
  background-position: -8px -113px;
  width: 40px;
  height: 40px;
}

.usp-wrape .usp i.sprite.analytic {
  background-position: -57px -112px;
  width: 40px;
  height: 41px;
}

.usp-wrape .usp i.sprite.certified,
.usp-wrape .usp i.sprite.verified-data {
  background-position: -98px -109px;
  width: 40px;
  height: 41px;
}

.usp-wrape .usp i.sprite.identify {
  background-position: -141px -109px;
  width: 41px;
  height: 41px;
}

.usp-wrape .usp i.sprite.global-reach {
  background-position: -241px -340px;
  width: 46px;
  height: 51px;
}

.usp-wrape .usp i.sprite.global-reach {
  background-position: -241px -340px;
  width: 46px;
  height: 51px;
}

.usp-wrape .usp i.sprite.user-design {
  background-position: -300px -338px;
  width: 49px;
  height: 48px;
}

.usp-wrape .usp i.sprite.end-support {
  background-position: -367px -337px;
  width: 49px;
  height: 49px;
}

.usp-wrape .usp i.sprite.integrity {
  background-position: -435px -338px;
  width: 43px;
  height: 48px;
}

.usp-wrape .usp i.sprite.innovation {
  background-position: -487px -336px;
  width: 44px;
  height: 48px;
}

.usp-wrape .usp i.sprite.collaboration {
  background-position: -540px -334px;
  width: 49px;
  height: 49px;
}

.usp-wrape .usp i.sprite.excellence {
  background-position: -542px -214px;
  width: 32px;
  height: 49px;
}

.usp-wrape .usp i.sprite.import-export {
  background-position: -243px -461px;
  width: 49px;
  height: 48px;
}

.usp-wrape .usp i.sprite.manufacturers {
  background-position: -308px -461px;
  width: 49px;
  height: 45px;
}

.usp-wrape .usp i.sprite.wholesealer {
  background-position: -371px -460px;
  width: 49px;
  height: 27px;
}

.usp-wrape .usp i.sprite.sme {
  background-position: -371px -460px;
  width: 49px;
  height: 27px;
}


.usp-wrape .usp:hover i.sprite.search {
  background-position: -8px -161px;
}

.usp-wrape .usp:hover i.sprite.analytic {
  background-position: -57px -159px;
}

.usp-wrape .usp:hover i.sprite.certified,
.usp-wrape .usp:hover i.sprite.verified-data {
  background-position: -98px -160px;
}

.usp-wrape .usp:hover i.sprite.identify {
  background-position: -141px -161px;
}

.usp-wrape .usp:hover i.sprite.global-reach {
  background-position: -241px -396px;
}

.usp-wrape .usp:hover i.sprite.user-design {
  background-position: -300px -396px;
}

.usp-wrape .usp:hover i.sprite.end-support {
  background-position: -367px -396px;
}

.usp-wrape .usp:hover i.sprite.integrity {
  background-position: -435px -395px;
}

.usp-wrape .usp:hover i.sprite.innovation {
  background-position: -487px -393px;
}

.usp-wrape .usp:hover i.sprite.collaboration {
  background-position: -540px -391px;
}

.usp-wrape .usp:hover i.sprite.excellence {
  background-position: -542px -270px;
}

.usp-wrape .usp:hover i.sprite.import-export {
  background-position: -243px -519px;
}

.usp-wrape .usp:hover i.sprite.manufacturers {
  background-position: -308px -520px;
}

.usp-wrape .usp:hover i.sprite.wholesealer {
  background-position: -369px -503px;
}

.usp-wrape .usp:hover i.sprite.sme {
  background-position: -369px -503px;
}

.usp-wrape .usp .detail h3 {
  color: var(--primary-color);
  text-transform: uppercase;
  position: relative;
  margin-bottom: 20px;
  line-height: 1.25;
}

.usp-wrape .usp .detail h3::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -3px;
  width: 80px;
  height: 2px;
  background: var(--secondry-color);
}

.usp-wrape .usp .detail p {
  color: #888888;
  margin-bottom: 20px;
}

.usp-wrape .usp:hover {
  box-shadow: none;
  background: var(--primary-color);
}

.usp-wrape .usp:hover h3 {
  color: var(--white);
}

.usp-wrape .usp:hover .detail h3::before {
  width: 100px;
}

.usp-wrape .usp:hover p {
  color: var(--light-primary-1);
}


.usp-wrape.dark .usp {
  border: 1px solid var(--light-primary);
}

.usp-wrape.dark .usp .detail h3 {
  color: var(--white);
}

.usp-wrape.dark .usp .detail p {
  color: var(--light-primary-1);
}

.usp-wrape.dark .usp i.sprite.search {
  background-position: -8px -161px;
}

.usp-wrape.dark .usp i.sprite.analytic {
  background-position: -57px -159px;
}

.usp-wrape.dark .usp i.sprite.certified {
  background-position: -98px -160px;
}

.usp-wrape.dark .usp i.sprite.identify {
  background-position: -141px -161px;
}

.usp-wrape.dark .usp i.sprite.integrity {
  background-position: -435px -395px;
}

.usp-wrape.dark .usp i.sprite.innovation {
  background-position: -487px -393px;
}

.usp-wrape.dark .usp i.sprite.collaboration {
  background-position: -540px -391px;
}

.usp-wrape.dark .usp i.sprite.excellence {
  background-position: -542px -270px;
}

.usp-wrape.dark .usp i.sprite.wholesealer {
  background-position: -369px -503px;
}

.usp-wrape.dark .usp:hover {
  border: 1px solid var(--usp-border);
  box-shadow: 5px 8px 24px rgba(0, 0, 0, 0.10);
  background: var(--white);
}

.usp-wrape.dark .usp:hover .detail h3 {
  color: var(--primary-color);
}

.usp-wrape.dark .usp:hover .detail p {
  color: #888888;
}

.usp-wrape.dark .usp:hover i.sprite.search {
  background-position: -8px -113px;
}

.usp-wrape.dark .usp:hover i.sprite.analytic {
  background-position: -57px -112px;
}

.usp-wrape.dark .usp:hover i.sprite.certified {
  background-position: -98px -109px;
}

.usp-wrape.dark .usp:hover i.sprite.identify {
  background-position: -141px -109px;
}

.usp-wrape.dark .usp:hover i.sprite.integrity {
  background-position: -435px -338px;
}

.usp-wrape.dark .usp:hover i.sprite.innovation {
  background-position: -487px -336px;
}

.usp-wrape.dark .usp:hover i.sprite.collaboration {
  background-position: -540px -334px;
}

.usp-wrape.dark .usp:hover i.sprite.excellence {
  background-position: -542px -214px;
}

.usp-wrape.dark .usp:hover i.sprite.wholesealer {
  background-position: -371px -460px;
}

/* USP box style END */


/* potetial style START */
.potention-wrape {
  display: flex;
  width: 100%;
  justify-content: center;
  position: relative;
  overflow: hidden;
  flex-wrap: wrap;
  margin: 50px 0;
}

.potention-wrape .container-fluid.bedges-wrape {
  position: absolute;
  max-width: 1600px;
  height: 100%;
  z-index: 3;
}

.potention-wrape .container-fluid.bedges-wrape .row {
  height: 100%;
}

.potention-wrape .left,
.potention-wrape .right {
  position: relative;
  width: 100%;
  height: 100%;
}

.potention-wrape .left .bedge {
  right: 0;
}

.potention-wrape .left .bedge .dote {
  position: absolute;
  right: -50px;
}

.potention-wrape .right .bedge .dote {
  position: absolute;
  left: -50px;
}

.potention-wrape .bedge {
  background: var(--white);
  padding: 25px 30px;
  border-radius: var(--unit32);
  border: 1px solid var(--primary-color);
  font-size: 18px;
  color: #333333;
  line-height: 1;
  display: inline-block;
  position: absolute;
}

.potention-wrape .bedge i {
  background-position: -400px -76px;
  width: 16px;
  height: 19px;
  display: inline-block;
  position: relative;
  top: calc(50% - 9px);
}

.potention-wrape .bedge:hover {
  background: var(--secondry-color);
  border-color: var(--secondry-color);
  color: var(--white);
}

.potention-wrape .bedge:hover i {
  background-position: -425px -76px;
}

.potention-wrape .left .bedge i {
  position: absolute;
  right: -15px;
}

.potention-wrape .right .bedge i {
  position: absolute;
  left: -15px;
  transform: rotate(180deg);
}

.potention-wrape .bedge .dote {
  width: var(--unit16);
  height: var(--unit16);
  border-radius: var(--unit8);
  background: var(--secondry-color);
  display: inline-block;
  top: calc(50% - 8px);
}

.potention-wrape .left .bedge.one {
  top: 70px;
  right: 190px;
}

.potention-wrape .left .bedge.two {
  top: 210px;
  right: 374px;
}

.potention-wrape .left .bedge.three {
  top: 360px;
  right: 443px;
}

.potention-wrape .left .bedge.four {
  bottom: 430px;
  right: 453px;
}

.potention-wrape .left .bedge.five {
  bottom: 290px;
  right: 420px;
}

.potention-wrape .left .bedge.six {
  bottom: 150px;
  right: 320px;
}

.potention-wrape .right .bedge.one {
  top: 90px;
  left: 240px;
}

.potention-wrape .right .bedge.two {
  top: 260px;
  left: 404px;
}

.potention-wrape .right .bedge.three {
  top: 410px;
  left: 452px;
}

.potention-wrape .right .bedge.four {
  bottom: 339px;
  left: 439px;
}

.potention-wrape .right .bedge.five {
  bottom: 170px;
  left: 350px;
}

.potention-wrape .avatar-round {
  background-color: var(--white);
  width: 852px;
  height: 852px;
  border-radius: 50%;
  margin: 70px;
  padding: 30px;
  position: relative;
  pointer-events: none;
}

.potention-wrape .avatar-round:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: var(--white);
  border-radius: 50%;
  box-shadow: 5px 0 126px rgba(0, 0, 0, 0.1);
  pointer-events: none;
}

.potention-wrape .avatar-round .inner {
  width: 792px;
  height: 792px;
  border: 1px solid #e4e4e4;
  background: var(--white);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  overflow: hidden;
  padding: 50px 0;
  box-shadow: 5px 0 126px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 2;
  pointer-events: none;
}

.potention-wrape .avatar-round .inner p {
  font-size: var(--unit24);
  line-height: 40px;
  color: var(--dark-gray);
  text-align: center;
  display: block;
  width: 60%;
}

.potention-wrape .pulse {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.5);
  animation: pulse 2s infinite;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.15);
  }

  70% {
    -webkit-box-shadow: 0 0 0 100px rgba(230, 18, 12, 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(230, 18, 12, 0);
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.15);
  }

  70% {
    -moz-box-shadow: 0 0 0 100px rgba(230, 18, 12, 0);
    box-shadow: 0 0 0 100px rgba(230, 18, 12, 0);
  }

  100% {
    -moz-box-shadow: 0 0 0 0 rgba(230, 18, 12, 0);
    box-shadow: 0 0 0 0 rgba(230, 18, 12, 0);
  }
}

/* potential style END */


/* find supplier wrape style START */
.find-supplier-wrape {
  width: 100%;
  display: block;
  padding: 0 20px;
}

.find-supplier-wrape .container-fluid {
  background: var(--primary-color);
  border-radius: var(--unit16);
  padding: 0px 23px 0;
}

.find-supplier-wrape .img-wrape {
  display: flex;
  justify-content: flex-end;
  position: relative;
}

.find-supplier-wrape .img-wrape img {
  border: 12px solid var(--white);
  border-radius: var(--unit32);
  position: relative;
  top: -66px;
}

.find-supplier-wrape .img-wrape .buble-lable {
  background: rgba(255, 255, 255, 0.1);
  width: 186px;
  height: 186px;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: -100px;
  z-index: 3;
  padding: 9px;
  box-shadow: 5px 22px 26px rgba(0, 0, 0, 0.20);
}

.find-supplier-wrape .img-wrape .buble-lable .inner {
  background: var(--primary-color);
  width: 168px;
  height: 168px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 30px 20px 20px;
}

.find-supplier-wrape .img-wrape .buble-lable h5 {
  font-size: 56px;
  font-weight: 700;
  margin-bottom: 10px;
  color: var(--white);
  line-height: 1;
  text-align: center;
}

.find-supplier-wrape .img-wrape .buble-lable p {
  font-size: var(--unit16);
  color: var(--white);
  line-height: 1.15;
  text-align: center;
}

.find-supplier-wrape .section-head {
  margin-top: 33px;
}

/* find supplier wrape style END */


/* Banner style START */
.banner-wrape {
  width: 100%;
  position: relative;
  margin-top: -65px;
  margin-bottom: 100px;
}

.banner-wrape .carousel.slide {
  border-bottom-right-radius: 40px;
  border-bottom-left-radius: 24px;
  overflow: hidden;
  z-index: 2;
  position: relative;
}

.banner-wrape:after {
  content: "";
  width: 100%;
  height: 100%;
  border-bottom-right-radius: 40px;
  border-bottom-left-radius: 24px;
  display: block;
  position: absolute;
  background: #f6f6f6;
  top: 10px;
  left: 0;
}

.banner-wrape button {
  height: 48px;
  width: 48px;
  border-radius: var(--unit16);
  border: 1px solid rgba(255, 255, 255, 0.5);
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  top: calc(50% - 24px);
  opacity: 1;
}

.banner-wrape button span.sprite {
  width: 14px;
  height: 21px;
  display: inline-block;
  box-shadow: 0 1px 24px rgba(0, 0, 0, 0.17);
}

.banner-wrape button:hover {
  opacity: 1;
  background: var(--white);
  box-shadow: 0 1px 24px rgba(0, 0, 0, 0.17);
}

.banner-wrape button.carousel-control-prev {
  left: 36px;
}

.banner-wrape button.carousel-control-next {
  right: 36px;
}

.banner-wrape button.carousel-control-prev span.sprite {
  background-position: -455px -49px;
}

.banner-wrape button.carousel-control-next span.sprite {
  background-position: -476px -76px;
}

.banner-wrape button.carousel-control-prev:hover span.sprite {
  background-position: -476px -49px;
}

.banner-wrape button.carousel-control-next:hover span.sprite {
  background-position: -455px -76px;
}

.banner-wrape .decoration {
  background-position: -9px -348px;
  width: 174px;
  height: 33px;
  position: absolute;
  bottom: -5px;
  left: calc(50% - (174px / 2));
  display: inline-block;
  z-index: 2;
}

/* Banner style END */


/* Logistics style START */
.logistics-lab-wrape {
  width: 100%;
  display: block;
  padding: 0 70px;
}

.logistics-lab-wrape .inner-pad {
  width: 100%;
  padding: 60px 30px 60px;
  background: var(--primary-color) url(../imgs/logistics-bg.png) right top no-repeat;
  border-top-left-radius: var(--unit32);
  border-top-right-radius: var(--unit32);
}

/* Logistics style END */

/* our partner style START */
.our-partner-wrape {
  width: 100%;
  padding: 0 20px;
  margin-bottom: 100px;
}

.our-partner-wrape .owl-carousel .owl-item {
  border: 1px solid #f0f0f0;
  border-radius: var(--unit8);
  padding: 20px;
}

.owl-carousel .owl-stage {
  padding: 25px 0;
}

.owl-carousel .owl-item:hover {
  box-shadow: 0 8px 26px rgba(0, 0, 0, 0.09);
  border: 1px solid #f0f0f0;
}

.our-partner-wrape .slider-wrape {
  padding: 0 60px;
  position: relative;
}

.our-partner-wrape .slider-wrape .custom-nav button {
  background: #f5f5f5;
  border-radius: calc((var(--unit8)) / 2);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: 1px solid #ececec;
}

.our-partner-wrape .slider-wrape .custom-nav button i {
  width: 8px;
  height: 13px;
  display: inline-block;
}

.our-partner-wrape .slider-wrape .custom-nav button.owl-prev {
  position: absolute;
  left: 0;
  top: 45%;
}

.our-partner-wrape .slider-wrape .custom-nav button.owl-prev i {
  background-position: -372px -68px;
}

.our-partner-wrape .slider-wrape .custom-nav button.owl-prev:hover i {
  background-position: -383px -68px;
}

.our-partner-wrape .slider-wrape .custom-nav button.owl-next {
  position: absolute;
  right: 0;
  top: 45%;
}

.our-partner-wrape .slider-wrape .custom-nav button.owl-next i {
  background-position: -383px -51px;
}

.our-partner-wrape .slider-wrape .custom-nav button.owl-next:hover i {
  background-position: -372px -51px;
}

/* our partner style END */

/* our services style START */
.our-service-wrape {
  width: 100%;
  margin-bottom: 100px;
}

.our-service-wrape .container-fluid {
  padding: 0 20px;
}

.our-service-wrape .card {
  background: var(--white) url(../imgs/service-bg.png) no-repeat top right;
  width: 100%;
  box-shadow: 5px 11px 30px rgba(0, 0, 0, 0.26);
  border: 1px solid var(--white);
  border-radius: var(--unit16) var(--unit16) var(--unit24) var(--unit32);
}

.our-service-wrape .card .img-wrape {
  background: url(../imgs/service-img.jpg) no-repeat 0 0;
  width: 100%;
  height: 100%;
  border-radius: var(--unit32) var(--unit8) var(--unit8) var(--unit32);
  background-size: cover;

  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}

.our-service-wrape .card .img-wrape .section-head {
  margin-left: 20px;
  margin-top: 50px;
  max-width: 70%;
}

.our-service-wrape .card .img-wrape .decorative {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.our-service-wrape .card .img-wrape .decorative::after {
  content: "";
  height: 3px;
  width: 30%;
  background: var(--secondry-color);
  justify-self: flex-end;
}

.our-service-wrape .card .img-wrape .decorative::before {
  content: "";
  width: 61%;
  height: 3px;
  background: var(--secondry-color);
}

.our-service-wrape .card .img-wrape .decorative i {
  background-position: -58px -76px;
  width: 32px;
  height: 24px;
  position: absolute;
  right: 33%;
  top: -12px;
}

.our-service-wrape .card .img-wrape .stats-wrape {
  position: relative;
  width: 100%;
  padding: 0 64px;
}

.our-service-wrape .stats-wrape .stat {
  display: flex;
  align-items: center;
  gap: 15px;
}

.our-service-wrape .stats-wrape .stat .number {
  color: var(--white);
  font-size: calc(var(--unit8) * 9);
  font-weight: 700;
  font-family: var(--fontInter);
  line-height: 1.25;
  margin-bottom: 5px;
}

.our-service-wrape .stats-wrape .stat .text {
  color: var(--white);
  font-size: 16px;
  line-height: 1.25;
}

.our-service-wrape .stats-wrape .stat .text span,
.our-service-wrape .stats-wrape .stat .text b {
  width: 100%;
  display: block;
  line-height: 1.15;
}

.our-service-wrape .stats-wrape .stat .text .big {
  color: var(--white);
  text-transform: uppercase;
  font-size: 24px;
}

.our-service-wrape .stats-wrape .up {
  display: flex;
  justify-content: flex-end;
}

.our-service-wrape .stats-wrape .down {
  display: flex;
  justify-content: flex-start;
}


.our-service-wrape .full {
  padding: 2px 0 0 1px;
}

.our-service-wrape .service-pad {
  padding-top: 30px;
  padding-bottom: 10px;
}

.our-service-wrape .service-pad .service {
  background: var(--white);
  box-shadow: 0 6px 28px rgba(0, 0, 0, 0.06);
  display: flex;
  margin-bottom: 20px;
  padding: 12px 12px 12px 95px;
  position: relative;
}

.our-service-wrape .service-pad .service i {
  position: absolute;
  left: 23px;
  top: 25px;
  width: 50px;
  height: 50px;
}

.our-service-wrape .service-pad .service i.shipment {
  background-position: -9px -217px;
}

.our-service-wrape .service-pad .service i.lead {
  background-position: -67px -212px;
}

.our-service-wrape .service-pad .service i.buySuply {
  background-position: -121px -212px;
}

.our-service-wrape .service-pad .service i.customise {
  background-position: -176px -217px;
}

.our-service-wrape .service-pad .service i.customer {
  background-position: -238px -212px;
}

.our-service-wrape .service-pad .service i.global {
  background-position: -290px -216px;
}

.our-service-wrape .service-pad .service i.hsn {
  background-position: -349px -213px;
}

.our-service-wrape .service-pad .service i.trade {
  background-position: -416px -217px;
}

.our-service-wrape .service-pad .service i.hot {
  background-position: -477px -216px;
}

.our-service-wrape .service-pad .service .detail {
  width: 100%;
  display: flex;
  position: relative;
  flex-wrap: wrap;
  border-left: 1px solid #eeeeee;
  padding: 10px 0 10px 22px;
}

.our-service-wrape .service-pad .service .detail h5,
.our-service-wrape .service-pad .service .detail p {
  font-size: 20px;
  width: 100%;
}

.our-service-wrape .service-pad .service .detail h5 {
  color: var(--black);
}

.our-service-wrape .service-pad .service .detail p {
  color: #999999;
  margin-bottom: 0;
}

.our-service-wrape .service-pad .service .export {
  position: absolute;
  width: 36px;
  height: 36px;
  background: #f7f7f7;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 20px;
  top: 30px;
}

.our-service-wrape .service-pad .service .export span {
  background-position: -323px -52px;
  width: 17px;
  height: 17px;
  display: inline-block;
}

.our-service-wrape .service-pad .service:hover .export span {
  background-position: -303px -72px;
}

.our-service-wrape .service-pad .service:hover {
  background: var(--primary-color);
}

.our-service-wrape .service-pad .service:hover .detail {
  border-left-color: #085087;
}

.our-service-wrape .service-pad .service:hover .detail h5 {
  color: var(--white);
}

.our-service-wrape .service-pad .service:hover .detail p {
  color: #b3b3b3;
}

.our-service-wrape .service-pad .service:hover .export {
  background: var(--light-primary);
}

.our-service-wrape .service-pad .service:hover i.shipment {
  background-position: -10px -275px;
}

.our-service-wrape .service-pad .service:hover i.lead {
  background-position: -67px -275px;
}

.our-service-wrape .service-pad .service:hover i.buySuply {
  background-position: -121px -275px;
}

.our-service-wrape .service-pad .service:hover i.customise {
  background-position: -177px -275px;
}

.our-service-wrape .service-pad .service:hover i.customer {
  background-position: -238px -275px;
}

.our-service-wrape .service-pad .service:hover i.global {
  background-position: -292px -278px;
}

.our-service-wrape .service-pad .service:hover i.hsn {
  background-position: -349px -275px;
}

.our-service-wrape .service-pad .service:hover i.trade {
  background-position: -416px -274px;
}

.our-service-wrape .service-pad .service:hover i.hot {
  background-position: -477px -274px;
}

/* our services style END */

/* global trade style START */
.global-trade-wrape {
  width: 100%;
  padding: 0 24px;
  background: url(../imgs/map-dots-bg.png) no-repeat center center;
  padding-bottom: 130px;
}

.global-trade-wrape .section-head {
  margin-bottom: 60px;
}

.global-trade-wrape .section-head .head h2 span {
  display: block;
  width: 100%;
}

.global-trade-wrape .country-wrape {
  display: flex;
  flex-wrap: wrap;
  border-left: 1px solid #a5a4a4;
  border-top: 1px solid #a5a4a4;
}

.global-trade-wrape .country-wrape .country {
  background: rgba(255, 255, 255, 0.75);
  width: calc(100% / 8);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #a5a4a4;
  border-bottom: 1px solid #a5a4a4;
  padding: 24px;
  opacity: 1;
  overflow: hidden;
  transition: all 0.15s linear 0.15s;
  position: relative;
}

.global-trade-wrape .country-wrape .country:hover {
  background: var(--white);
  opacity: 1;
  border-color: #e2e2e2;
  box-shadow: 5px 8px 32px rgba(0, 0, 0, 0.11);
  position: relative;
  z-index: 1;
  transform: scale(1.01);
  transition: all 0.15s linear 0.15s;
  cursor: pointer;
}

.global-trade-wrape .country-wrape .country:after {
  background: url(../imgs/sprite.svg) no-repeat -372px -35px;
  content: "";
  width: 13px;
  height: 10px;
  display: inline-block;
  position: absolute;
  left: 50%;
  bottom: 2px;
  display: none;
}

.global-trade-wrape .country-wrape .country:before {
  content: "";
  position: absolute;
  width: 80%;
  left: 10%;
  bottom: 0;
  background: #e7405a;
  height: 3px;
  border-radius: 3px 3px 0 0;
  display: none;
}

.global-trade-wrape .country-wrape .country:hover:after,
.global-trade-wrape .country-wrape .country:hover:before {
  display: block;
}

.global-trade-wrape .country-wrape .country:last-child {
  border: 0;
}


.global-trade-wrape .country-wrape .country i.flag {
  background: url(../imgs/flags.png) no-repeat;
  width: 67px;
  height: 44px;
  border-radius: calc((var(--unit8)) / 2);
  margin-bottom: 5px;
}

.global-trade-wrape .country-wrape .country.china i.flag {
  background-position: -268px -63px;

}

.global-trade-wrape .country-wrape .country.us i.flag {
  background-position: -6px -6px;


}

.global-trade-wrape .country-wrape .country.india i.flag {
  background-position: -6px -62px;

}

.global-trade-wrape .country-wrape .country.srilanka i.flag {
  background-position: -6px -118px;

}

.global-trade-wrape .country-wrape .country.vietnam i.flag {
  background-position: -6px -176px;

}

.global-trade-wrape .country-wrape .country.argentina i.flag {
  background-position: -5px -235px;

}

.global-trade-wrape .country-wrape .country.chile i.flag {
  background-position: -5px -299px;

}

.global-trade-wrape .country-wrape .country.columbia i.flag {
  background-position: -268px -177px;

}

.global-trade-wrape .country-wrape .country.ecuador i.flag {
  background-position: -92px -6px;

}

.global-trade-wrape .country-wrape .country.mexico i.flag {
  background-position: -94px -62px;

}

.global-trade-wrape .country-wrape .country.panama i.flag {
  background-position: -93px -118px;

}

.global-trade-wrape .country-wrape .country.paraguay i.flag {
  background-position: -93px -176px;

}

.global-trade-wrape .country-wrape .country.philippines i.flag {
  background-position: -93px -235px;

}

.global-trade-wrape .country-wrape .country.uruguay i.flag {
  background-position: -93px -299px;

}

.global-trade-wrape .country-wrape .country.venezuela i.flag {
  background-position: -93px -359px;

}

.global-trade-wrape .country-wrape .country.bangladesh i.flag {
  background-position: -180px -6px;

}

.global-trade-wrape .country-wrape .country.cameroon i.flag {
  background-position: -180px -62px;

}

.global-trade-wrape .country-wrape .country.costarica i.flag {
  background-position: -180px -118px;

}

.global-trade-wrape .country-wrape .country.ghana i.flag {
  background-position: -268px -118px;

}

.global-trade-wrape .country-wrape .country.kazakhstan i.flag {
  background-position: -176px -176px;

}

.global-trade-wrape .country-wrape .country.pakistan i.flag {
  background-position: -180px -235px;

}

.global-trade-wrape .country-wrape .country.tanzania i.flag {
  background-position: -179px -299px;

}

.global-trade-wrape .country-wrape .country.turkey i.flag {
  background-position: -180px -359px;

}



.global-trade-wrape .country-wrape .country .export {
  position: absolute;
  width: 36px;
  height: 36px;
  background: var(--secondry-color);
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 50px;
  top: 50px;
  margin: 0px 5px;

}



.global-trade-wrape .country-wrape .country:hover .export {
  background: var(--secondry-color);
}

.global-trade-wrape .country-wrape .country .export span {
  background-position: -323px -52px;
  width: 17px;
  height: 17px;
  display: inline-block;
}

.global-trade-wrape .country-wrape .country .explore_more {
  font-size: var(--unit24);
  text-align: left;
}

.global-trade-wrape .country-wrape .country:hover .export span {
  background-position: -303px -72px;
}

.global-trade-wrape .country-wrape .country h6 {
  width: 100%;
  text-align: center;
}

.global-trade-wrape .country-wrape .country p {
  margin-bottom: 0;
}

/* global trade style END */

/* contact wrape style START */
.contact-wrape {
  width: 100%;
  padding: 0 10px;
}

.contact-wrape .box-inner {
  background: var(--primary-color);
  border-top-left-radius: var(--unit32);
  border-top-right-radius: var(--unit32);
  overflow: hidden;
  width: 100%;
}

.contact-wrape .up-wrape {
  background: url(../imgs/contact-bg.jpg);
  width: 100%;
  height: 339px;
  position: relative;
  display: flex;
  align-items: flex-end;
  padding: 0 60px 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.contact-wrape .up-wrape h5 {
  color: var(--white);
  font-size: 36px;
  font-family: var(--fontInter);
  font-weight: 500;
  width: 40%;
  line-height: 1.25;
}

.contact-wrape .down-wrape {
  padding: 20px 100px 0px 50px;
  position: relative;
}

.contact-wrape .down-wrape .detail-part {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding-right: 50px;
  padding-bottom: 50px;
}

.contact-wrape .down-wrape .contact-wrape {
  display: flex;
  border-bottom: 1px solid var(--light-primary);
}

.contact-wrape .down-wrape .form-wrape {
  background: var(--white);
  padding: 30px 46px 40px 26px;
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
  box-shadow: 0 0 49px rgba(0, 0, 0, 0.41);
  position: relative;
  margin-top: -145px;
}

.contact-wrape .down-wrape .form-wrape .section-head {
  margin-bottom: 45px;
}

.contact-wrape .down-wrape .form-wrape .section-head .head {
  margin-bottom: 15px;
}

.contact-wrape .down-wrape .form-wrape .section-head:after {
  content: "";
  width: calc(100% - 40px);
  position: absolute;
  bottom: -20px;
  left: 20px;
  height: 1px;
  background: #ececec;
}

.contact-wrape .down-wrape .form-wrape .section-head h2 {
  font-size: 36px;
}

.contact-wrape .down-wrape .form-wrape form {
  padding: 0 16px 0 26px;
  position: relative;
}

.contact-wrape .down-wrape .form-wrape hr {
  width: calc(100% - 50px);
  margin-left: 10px;
}

.contact-wrape form .PhoneInputInput {
  border: 0;
  padding: 0;
}

.contact-wrape .down-wrape .form-wrape form .inquiry-form-btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.contact-wrape .down-wrape .form-wrape form .inquiry-form-btn .secondry-btn {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 700;
  padding: 14px 30px;
}

.contact-wrape .contact-pad h6,
.contact-wrape .contact-pad p {
  font-family: var(--fontInter);
  margin-bottom: 15px;
}

.contact-wrape .contact-pad {
  display: flex;
  width: 100%;
  border-bottom: 1px solid rgba(46, 155, 240, 0.8);
  padding: 42px 0 30px;
}

.other-details {
  margin-top: 30px;
}

.contact-wrape .contact-pad h6,
.other-details h6 {
  color: var(--white);
  font-weight: 500;
  font-size: 23px;
  margin-bottom: 30px;
}

.contact-wrape .contact-pad p,
.contact-wrape .contact-pad a {
  color: var(--light-primary-1);
  padding-left: 30px;
}

.contact-wrape .contact-pad a {
  width: 100%;
  display: block;
  margin-bottom: 15px;
  padding-left: 0;
  display: flex;
  align-items: center;
  gap: 10px;
}

.contact-wrape .contact-pad a i {
  display: inline-block;
}

.contact-wrape .contact-pad a.number i {
  background-position: -224px -156px;
  width: 24px;
  height: 24px;
}

.contact-wrape .contact-pad a.email i {
  background-position: -253px -157px;
  width: 23px;
  height: 21px;
}

.contact-wrape .contact-pad a:hover {
  color: var(--white);
}

.contact-wrape .contact-pad .address,
.contact-wrape .contact-pad .other {
  width: 50%;
}

.contact-wrape .contact-pad .address {
  padding-right: 100px;
}

.contact-wrape .contact-pad .address p {
  position: relative;
}

.contact-wrape .contact-pad .address p i {
  position: absolute;
  left: 0;
  top: 0;
  background-position: -194px -155px;
  width: 21px;
  height: 25px;
}

.contact-wrape .stats-wrape {
  display: flex;
  flex-wrap: wrap;
  margin-top: 42px;
}

.contact-wrape .stats-wrape .stat {
  display: flex;
  align-items: center;
  gap: 15px;
}

.contact-wrape .stats-wrape .stat .number {
  color: var(--light-primary);
  font-size: calc(var(--unit8) * 9);
  font-weight: 600;
}

.contact-wrape .stats-wrape .stat .text {
  color: var(--light-primary-1);
  font-size: 16px;
}

.contact-wrape .stats-wrape .stat .text span,
.contact-wrape .stats-wrape .stat .text b {
  width: 100%;
  display: block;
  line-height: 1.15;
}

.contact-wrape .stats-wrape .stat .text .big {
  color: var(--white);
  text-transform: uppercase;
  font-size: 24px;
}

.contact-wrape .stats-wrape .stat {
  border: 1px solid rgba(46, 155, 240, 0.5);
  width: 50%;
  padding-top: 30px;
  padding-bottom: 30px;
}

.contact-wrape .stats-wrape .stat.one {
  border: 0;
}

.contact-wrape .stats-wrape .stat.two {
  border-top: 0;
  border-right: 0;
  padding-left: 50px;
}

.contact-wrape .stats-wrape .stat.three {
  border-bottom: 0;
  border-left: 0;
}

.contact-wrape .stats-wrape .stat.four {
  border: 0;
  padding-left: 50px;
}

/* contact wrape style END */

/* footer style START */
footer {
  position: relative;
  padding: 0 20px;
  margin-bottom: 20px;
}

footer .decorative {
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 43.5%;
  z-index: 9;
  right: 134px;
}

footer .decorative::after {
  content: "";
  height: 3px;
  width: 18%;
  background: var(--secondry-color);
  justify-self: flex-end;
}

footer .decorative::before {
  content: "";
  width: 78%;
  height: 3px;
  background: var(--secondry-color);
}

footer .decorative i {
  background-position: -58px -76px;
  width: 32px;
  height: 24px;
  position: absolute;
  right: 19%;
  top: -12px;
}

footer .right-section .inner-pad {
  display: flex;
  width: 100%;
}

footer .right-section .inner-pad .section-pad {
  width: calc(100% / 3);
}

footer .footer-about {
  width: 100%;
  display: flex;
  margin-bottom: 30px;
  flex-wrap: wrap;
  gap: 24px;
}

footer .footer-about p {
  width: 100%;
}

footer .footer {
  background: var(--light-white-gray);
  width: 100%;
  padding: 54px 35px;
  display: flex;
}

footer .footer .img-wrape {
  width: 33.33333333%;
  border-right: 1px solid var(--white);
}

footer .footer .content-wrape {
  width: 66.67%;
  border-left: 1px solid #e4e4e4;
  padding-left: 50px;
}

footer .footer .content-wrape h6 {
  text-transform: uppercase;
  color: var(--primary-color);
  margin-bottom: 22px;
}

footer .footer .content-wrape li {
  width: 100%;
  display: block;
  margin-bottom: 15px;
  padding-right: 5px;
}

footer .footer .content-wrape li a {
  padding-left: 30px;
  position: relative;
  display: inline-block;
}

footer .footer .content-wrape li i {
  background-position: -520px -53px;
  width: 16px;
  height: 9px;
  position: absolute;
  left: 0;
  top: 6px;
  transition: all 0.25s linear 0.25s;
}

footer .footer .content-wrape li a:hover {
  color: var(--secondry-color);
}

footer .footer .content-wrape li a:hover i {
  background-position: -500px -53px;
  transition: all 0.25s linear 0.25s;
  left: 5px;
}

footer .footer .content-wrape .f-logo {
  background-position: -6px -5px;
  width: 173px;
  height: 59px;
  display: inline-block;
}

footer .footer .content-wrape .social-media {
  background: var(--white);
  border-radius: var(--unit16);
  display: inline-flex;
  align-items: center;
  overflow: hidden;
}

footer .footer .content-wrape .social-media a {
  border-right: 1px solid var(--light-white-gray);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 20px;
}

footer .footer .content-wrape .social-media a:last-child {
  border: 0;
}

footer .footer .content-wrape .social-media a i {
  display: inline-block;
}

footer .footer .content-wrape .social-media a.linkedIn i {
  background-position: -285px -108px;
  width: 25px;
  height: 24px;
}

footer .footer .content-wrape .social-media a.fb i {
  background-position: -320px -108px;
  width: 14px;
  height: 25px;
}

footer .footer .content-wrape .social-media a.insta i {
  background-position: -345px -109px;
  width: 27px;
  height: 27px;
}

footer .footer .content-wrape .social-media a.twitter i {
  background-position: -380px -109px;
  width: 22px;
  height: 21px;
}

footer .footer .content-wrape .social-media a:hover {
  background: var(--primary-color);
}

footer .footer .content-wrape .social-media a.linkedIn:hover i {
  background-position: -285px -143px;
}

footer .footer .content-wrape .social-media a.fb:hover i {
  background-position: -320px -143px;
}

footer .footer .content-wrape .social-media a.insta:hover i {
  background-position: -345px -144px;
}

footer .footer .content-wrape .social-media a.twitter:hover i {
  background-position: -380px -143px;
}

footer.white-bg {
  background: var(--white);
}

footer.white-bg.full {
  padding: 0;
}

footer.white-bg.full .footer-bottom {
  border-radius: 0;
}

.footer-bottom {
  width: 100%;
  background: var(--dark-gray);
  border-bottom-right-radius: var(--unit16);
  border-bottom-left-radius: var(--unit16);
  display: flex;
  justify-content: space-between;
  color: var(--white);
  padding: var(--unit24);
}

.footer-bottom a {
  color: var(--white);
  opacity: 0.9;
}

.footer-bottom a:hover {
  opacity: 1;
}

.footer-bottom a.footer-design:hover {
  color: var(--secondry-color);
}

.flag {
  background: url(../imgs/flags.png) no-repeat;
  width: 70px;
  height: 46px;
  display: inline-block;
  margin-right: 10px;
  content: "";
}

.flag.usa {
  background-position: -6px -6px;
}

.flag.india {
  background-position: -6px -62px;
}

.flag.uae {
  background-position: -267px -6px;
}

/* footer style END */



/* register style START */
.register-wrape.strip {
  position: relative;
  padding: 50px 0 20px;
  background: var(--primary-color);
  background: var(--primary-color) url("../imgs/register-strip-bg.png") no-repeat 0 0;
  background-repeat: no-repeat;
  width: 100%;
  height: auto;
  margin: 0 0 80px;
}

.register-wrape.strip .register-btn-big {
  background: var(--white);
  border-radius: var(--unit16);
  padding: 1px 20px 1px 1px;
  box-shadow: 5px 11px 30px rgba(0, 0, 0, 0.25);
  font-size: var(--unit24);
  width: 290px;
  display: flex;
  align-items: center;
  gap: 15px;
  color: #000;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 1.15;
}

.register-wrape.strip .register-btn-big span {
  background: var(--secondry-color);
  border-radius: 15px;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.register-wrape.strip .register-btn-big:hover {
  background: var(--light-primary-2);
  color: var(--white);
}

.register-wrape.strip .register-btn-big:hover span {
  background: var(--primary-color);
}

.register-wrape.strip .register-btn-big span i {
  background-position: -192px -110px;
  width: 50px;
  height: 34px;
  display: inline-block;
}

.register-wrape.strip .registration_text {
  display: flex;
  justify-content: center;
  align-items: center;
}

.register-wrape.strip .registration_text h3 {
  font-size: var(--unit16);
  color: var(--black);

}

.register-wrape.strip .registration_text h2 {
  font-size: var(--unit24);
  color: var(--black);

}

.register-wrape.strip .section-head {
  padding-right: 20%;
  margin-bottom: 0;
}

.register-wrape.strip .section-head a {
  color: var(--light-primary);
}

.register-wrape.strip .section-head a:hover {
  color: #8ec8f0;
}

.register-wrape.strip .decorative {
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  z-index: 9;
  top: 0;
}

.register-wrape.strip .decorative::after {
  content: "";
  height: 6px;
  width: 18.3%;
  background: #2f71a2;
  justify-self: flex-end;
  top: 3px;
  position: relative;
}

.register-wrape.strip .decorative::before {
  content: "";
  width: 78.30%;
  height: 6px;
  background: #2f71a2;
  position: relative;
  top: 3px;
}

.register-wrape.strip .decorative i {
  background-position: -245px -57px;
  width: 52px;
  height: 39px;
  position: absolute;
  right: 19%;
  top: -16px;
}

/* register style END */



/* inner banner style START */
.inner-banner-wrape {
  position: relative;
  width: 100%;
  height: 502px;
  border-bottom-right-radius: var(--unit32);
  border-bottom-left-radius: var(--unit32);
  background: var(--gray-text);
  margin-bottom: 80px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.inner-banner-wrape::after {
  content: "";
  width: 100%;
  height: 100%;
  border-bottom-right-radius: 40px;
  border-bottom-left-radius: 24px;
  display: block;
  position: absolute;
  background: #f6f6f6;
  bottom: -10px;
  left: 0;
  z-index: -1;
}

.inner-banner-wrape .inner {
  position: relative;
  z-index: 1;
  background: var(--white);
  border-radius: var(--unit8) var(--unit8) var(--unit32) var(--unit16);
  padding: 18px 27px;
  font-family: var(--fontFuturaBold);
  color: var(--secondry-color);
  font-size: 36px;
  text-transform: uppercase;
}

.inner-banner-wrape .inner:after {
  border: 1px solid var(--white);
  border-radius: var(--unit8) var(--unit8) var(--unit16) var(--unit16);
  position: absolute;
  bottom: -5px;
  left: 0;
  content: "";
  display: inline-block;
  width: 100%;
  height: 100%;
  opacity: 0.3;
}

.inner-banner-wrape {
  background-size: cover !important;
}

.inner-banner-wrape.global-advertising {
  background: url(../imgs/global-advertising-Banner.png) no-repeat;
}

.inner-banner-wrape.global-email-promotions {
  background: url(../imgs/global-email-pramotion-Banner.png) no-repeat;
}

.inner-banner-wrape.lead-reports-wrape {
  background: url(../imgs/lead-report-Banner.png) no-repeat;
}

.inner-banner-wrape.seasonal-promotion-wrape {
  background: url(../imgs/seasonal-promotion-Banner.png) no-repeat;
}

.inner-banner-wrape.reference-verification-wrape {
  background: url(../imgs/reference-verification-Banner.png) no-repeat;
}

.inner-banner-wrape.guarantee-prospective-business {
  background: url(../imgs/guarantee-prospective-business-Banner.png) no-repeat;
}

.inner-banner-wrape.logistics-trade-wrape {
  background: url(../imgs/logistics-trade-wrape-Banner.png) no-repeat;
}

.inner-banner-wrape.trade-safety-guarantee-wrape {
  background: url(../imgs/trade-safety-guarantee-Banner.png) no-repeat;
}

.benefits.page .icon-text i,
.services.page .icon-text i {
  display: inline-block;
  position: absolute;
  left: 30px;
  top: 5px;
}

.benefits.page .icon-text,
.services.page .icon-text {
  padding-left: 100px;
  position: relative;
  margin-bottom: 10px;
}

.benefits.page .icon-text h3,
.services.page .icon-text h3 {
  font-family: var(--fontFuturaBold);
  text-transform: uppercase;
  margin-bottom: 10px;
  font-size: var(--unit24);
  color: var(--primary-color);
  line-height: 1.25;
}

.benefits.page .icon-text i.targeted {
  background-position: -9px -419px;
  width: 48px;
  height: 48px;
}

.benefits.page .icon-text i.lead {
  background-position: -67px -419px;
  width: 39px;
  height: 49px;
}

.benefits.page .icon-text i.global,
.services.page .icon-text i.supply {
  background-position: -241px -340px;
  width: 46px;
  height: 51px;
}

.benefits.page .icon-text i.personalized {
  background-position: -122px -418px;
  width: 49px;
  height: 47px;
}

.benefits.page .icon-text i.deals,
.services.page .icon-text i.partners {
  background-position: -292px -218px;
  width: 46px;
  height: 47px;
}

.benefits.page .icon-text i.visibility {
  background-position: -9px -531px;
  width: 49px;
  height: 48px;
}

.benefits.page .icon-text i.times {
  background-position: -130px -531px;
  width: 49px;
  height: 48px;
}

.benefits.page .icon-text i.competitors {
  background-position: -67px -529px;
  width: 49px;
  height: 49px;
}

.benefits.page .icon-text i.partners {
  background-position: -540px -334px;
  width: 49px;
  height: 50px;
}

.benefits.page .icon-text i.trend {
  background-position: -67px -419px;
  width: 39px;
  height: 49px;
}

.benefits.page .icon-text i.grow,
.services.page .icon-text i.data {
  background-position: -71px -218px;
  width: 44px;
  height: 36px;
}

.benefits.page .icon-text i.trust {
  background-position: -98px -110px;
  width: 33px;
  height: 42px;
}

.benefits.page .icon-text i.management {
  background-position: -177px -218px;
  width: 48px;
  height: 46px;
}

/* inner banner style END */



.about-us-page-wrape .vision-mision-value {
  float: left;
  width: 100%;
  background: url(../imgs/map-dots-bg.png) no-repeat center 30% fixed;
}

.about-us-page-wrape .register-wrape.strip {
  margin-bottom: 0;
}


/* register page style START */
.register-wrape.page {
  background: url(../imgs/register-page-bg.jpg) no-repeat center center;
  background-size: cover;
  width: 100%;
  min-height: 80vh;
  margin-bottom: 0;
  margin-top: -58px;
}

.register-wrape.page .regi-form {
  background: var(--white);
  padding: 30px 72px 30px 30px;
  border-radius: var(--unit32) var(--unit16);
  max-width: 768px;
}

.main-regi {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-height: calc(100vh - 80px);
  margin-right: 30px;
  padding: 15px 0;
}

.register-wrape.page hr {
  width: calc(100% - 48px);
  margin-left: 10px;
}

.register-wrape.page .section-head:after {
  content: "";
  width: calc(100% - 40px);
  position: absolute;
  bottom: -20px;
  left: 20px;
  height: 1px;
  background: #ececec;
}

.register-wrape.page .section-head,
.register-wrape.page .section-head .head {
  margin-bottom: 0px;
}

.register-wrape.page .section-head .head h2 {
  font-size: 36px;
  line-height: 1;
}

.register-wrape.page .container-fluid {
  padding-left: 20px;
}

.register-wrape.page .section-head {
  margin-bottom: 30px;
}

.input-group,
.input-wrape {
  margin-bottom: 25px;
  width: 100%;
  position: relative;
  flex-wrap: wrap;
  align-items: center;

}

.input-group .required::after,
.input-wrape .required::after {
  color: red;
  content: " *";
}

.input-group label,
.input-wrape label {
  width: 100%;
  display: block;
  margin-bottom: 5px;
}

.input-group .invalid-feedback,
.input-wrape .invalid-feedback {
  bottom: -17px;
  margin-top: 0;
  line-height: 1;
}

.input-group .input-pad,
.input-wrape .input-pad {
  width: 100%;
  display: block;
  position: relative;
}

.input-group .input-pad.split,
.input-wrape .input-pad.split {
  display: flex;
  gap: 5px;
  border: 1px solid var(--usp-border);
  border-radius: 4px !important;
}

.input-group .input-pad.split .form-control,
.input-wrape .input-pad.split .form-control {
  border: 0 !important;
}

.input-group .input-pad.split .input-group-addon {
  justify-content: center;
  display: flex;
  padding: 5px;
  background: none !important;
}

.input-group-text {
  position: absolute;
  right: 2px;
  bottom: 2px;
  background: var(--primary-color);
  border: 0;
  padding: 9px 8px;
  color: var(--white);
}

.register-wrape.page .login .input-group .input-pad,
.register-wrape.page .login .input-wrape .input-pad {
  margin-bottom: 35px;
}

.register-wrape.page .login .input-group .invalid-feedback,
.register-wrape.page .login .input-wrape .invalid-feedback {
  bottom: -27px;
}

.register-wrape .btn-wrape {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}

.register-wrape.page .login-text,
.register-wrape.page .login-text {
  display: block;
}

.input-group .input-pad span+div,
.input-wrape .input-pad span+div {
  border-color: #ebebeb !important;
}

.error-wrape {
  position: relative;
  margin-bottom: 10px;
}

.error-wrape .invalid-feedback {
  position: relative;
  padding: 8px 10px 8px;
  background: var(--light-white-gray);
  border-radius: 8px;
}

.message-done .registration-success {
  min-height: inherit;
}

.message-done.register-wrape.page .regi-form {
  max-width: 500px;
}

.r-buttn {
  display: flex;
  flex-wrap: nowrap;
}

/* register page style END */

/* contact us page style START */
.contact-us-wrape+footer .decorative {
  display: none;
}

.contact-us-wrape .map-wrape {
  width: 100%;
  margin: 0;
  height: 560px;
  overflow: hidden;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.25);
  position: relative;
  opacity: 0.85;
}

.contact-us-wrape .map-bg {
  padding: 80px 0 0;
  position: relative;
}

.contact-us-wrape .map-bg::before {
  background: url(../imgs/map-dots-bg.png) no-repeat top center;
  position: absolute;
  width: 140%;
  height: 100%;
  content: "";
  display: inline-block;
  left: -20%;
  background-size: 100%;
  top: 0;
  opacity: 0.5;
}

.contact-us-wrape .map-bg .form-wrape {
  padding: 15px 15px 15px 0;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0;
}

.contact-us-wrape .contact-wrape {
  padding: 0 20px;
}

.contact-us-wrape .contact-wrape .box-inner {
  background: var(--primary-color);
  border-top-left-radius: var(--unit32);
  border-top-right-radius: var(--unit32);
  overflow: hidden;
  width: 100%;
  padding: 0;
  border: 10px solid var(--primary-color);
  border-bottom: 0;
}

.contact-us-wrape .form-wrape {
  padding: 30px 20px;
  background: white;
  border-radius: 18px;
}

.contact-us-wrape .form-wrape .inquiry-form-btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.contact-us-wrape .form-wrape .input-wrape.captcha-wrape {
  margin-bottom: 0;
}

.contact-us-wrape .detail-part {
  padding-left: 30px;
}

.contact-us-wrape .other a {
  width: 70px;
  height: 46px;
  display: inline-block;
  margin-right: 10px;
}

.contact-us-wrape p,
.contact-us-wrape a {
  padding-left: 30px;
  position: relative;
  font-size: var(--unit16);
  color: #424242;
  margin-bottom: 15px;
}

.contact-us-wrape a {
  width: 100%;
  display: block;
  margin-bottom: 15px;
  padding-left: 0;
  display: flex;
  align-items: center;
  gap: 10px;
}

.contact-us-wrape a:hover {
  color: var(--secondry-color);
}

.contact-us-wrape a i {
  display: inline-block;
}

.contact-us-wrape a.number i {
  background-position: -224px -185px;
  width: 24px;
  height: 24px;
}

.contact-us-wrape a.email i {
  background-position: -253px -186px;
  width: 23px;
  height: 21px;
}

.outer_pages_phone_input input {
  border: none;
  outline: none;
  height: inherit;
}

.contact-us-wrape .white-wrape {
  display: flex;
  background: white;
  width: 100%;
  height: 100%;
  position: relative;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
  border-radius: 30px 30px 0 0;
  padding: 15px;
}

.contact-us-wrape .white-wrape .address p i {
  position: absolute;
  left: 0;
  top: 0;
  background-position: -194px -184px;
  width: 21px;
  height: 25px;
}

.contact-us-wrape .white-wrape .contact-pad {
  display: flex;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.10);
  padding: 15px 0 10px;
}

.contact-us-wrape .white-wrape .other-details {
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.10);
  padding: 0 0 10px;
}

.contact-us-wrape .white-wrape .other-details+.other-details {
  border-bottom: 0;
  padding-bottom: 0;
}

.contact-us-wrape h6 {
  color: var(--primary-color);
  font-family: var(--fontFuturaBold);
  font-size: 26px;
  margin-bottom: 15px;
}

/* contact us page style END */

.sticky-footer {
  display: none;
}

.sticky-footer .Drop-down-menu__control,
.sticky-footer .creatable_react_select__control {
  cursor: pointer !important;
  border: 1px solid transparent !important;

}

.individual-error {
  position: relative;
  width: 100%;
}

.individual-error .invalid-feedback {
  position: relative;
  bottom: inherit;
}

@media screen and (max-height: 800px) {
  .register-wrape.page.login-page-wrape {
    margin-top: 0;
  }
}

@media screen and (max-width: 1899px) {
  .usp-wrape .usp .detail h3 {
    font-size: 24px;
  }

  .usp-wrape .usp .detail p {
    font-size: 14px;
  }

  .section-head .head h2 {
    font-size: 40px;
    line-height: 1.15;
  }

  .find-supplier-wrape .container-fluid {
    padding: 0px 10px 0;
  }

  .potention-wrape .container-fluid.bedges-wrape {
    max-width: 1300px;
  }

  .potention-wrape .avatar-round {
    background-color: var(--white);
    width: 652px;
    height: 652px;
    border-radius: 50%;
    margin: 70px;
    padding: 30px;
    position: relative;
  }

  .potention-wrape .avatar-round .inner {
    width: 592px;
    height: 592px;
    border: 1px solid #e4e4e4;
    background: var(--white);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    overflow: hidden;
    padding: 50px 0;
    box-shadow: 5px 0 126px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 2;
  }

  .potention-wrape .container-fluid.bedges-wrape {
    max-width: 1300px;
  }

  .potention-wrape .avatar-round {
    width: 612px;
    height: 612px;
  }

  .potention-wrape .avatar-round .inner {
    width: 552px;
    height: 552px;
    padding: 40px 60px;
  }

  .potention-wrape .avatar-round .inner img {
    max-width: 100%;
  }

  .potention-wrape .avatar-round .inner p {
    font-size: var(--unit16);
    line-height: 1.5;
    width: 100%;
  }

  .potention-wrape .bedge {
    font-size: 15px;
    line-height: 1.25;
    padding: 15px 15px;
  }

  .potention-wrape .left .bedge.one {
    top: 86px;
    right: 182px;
  }

  .potention-wrape .left .bedge.two {
    top: 189px;
    right: 290px;
  }

  .potention-wrape .left .bedge.three {
    top: 290px;
    right: 330px;
  }

  .potention-wrape .left .bedge.five {
    bottom: 200px;
    right: 296px;
  }

  .potention-wrape .left .bedge.six {
    bottom: 105px;
    right: 210px;
  }

  .potention-wrape .left .bedge.four {
    bottom: 300px;
    right: 333px;
  }

  .potention-wrape .right .bedge.one {
    top: 100px;
    left: 201px;
  }

  .potention-wrape .right .bedge.two {
    top: 220px;
    left: 304px;
  }

  .potention-wrape .right .bedge.three {
    top: 350px;
    left: 335px;
  }

  .potention-wrape .right .bedge.four {
    bottom: 209px;
    left: 299px;
  }

  .potention-wrape .right .bedge.five {
    bottom: 100px;
    left: 210px;
  }

  .contact-us-wrape {
    overflow: hidden;
  }

  .find-supplier-wrape .img-wrape .buble-lable {
    left: -60px;
  }
}

@media screen and (max-width: 1700px) {

  .primary-btn,
  .secondry-btn,
  .border-btn {
    font-size: 14px;
  }

  .global-trade-wrape .country-wrape .country .explore_more {
    font-size: 20px;
  }

  .global-trade-wrape .country-wrape .country .export {
    right: 30px;
  }
}

@media screen and (max-width: 1600px) {
  .usp-wrape .usp {
    padding: 20px 15px 20px 75px;
  }

  .usp-wrape .usp i.sprite {
    left: 15px;
  }

  .usp-wrape .usp .detail h3 {
    font-size: 22px;
  }

  .find-buyer-wrape {
    margin-bottom: 80px;
  }

  .find-supplier-wrape .img-wrape .buble-lable {
    left: -100px;
  }

  .our-service-wrape .card .img-wrape .section-head {
    max-width: 90%;
    margin-left: 10px;
  }

  .our-service-wrape .service-pad {
    padding: 30px 10px 10px;
  }

  .contact-wrape .down-wrape .form-wrape .section-head p br {
    display: none;
  }

  .contact-wrape .down-wrape {
    padding: 20px 30px 0px 30px;
  }

  .contact-wrape .down-wrape .detail-part {
    padding-right: 20px;
  }

  footer .decorative {
    width: 44.5%;
    right: 64px;
  }

  .contact-wrape .contact-pad .address {
    padding-right: 60px;
  }

  header .brand-wrape {
    width: 285px;
    height: 130px;
  }

  header .brand-wrape .brand img {
    max-width: 230px;
  }

  header .down-header {
    width: calc(100% - 20px);
    padding-left: 305px;
  }

  header .down-header .find-btn {
    font-size: 14px;
  }

  header .down-header .menu-wrape a.nav-link {
    font-size: 14px;
    padding: 8px 20px 8px 27px;
  }

  header .down-header .menu-wrape a.nav-link::before {
    left: 15px;
  }

  header .up-header {
    padding: 6px 20px 6px 0;
  }

  header .up-header li .form-control {
    padding: 6px 12px;
  }

  .primary-btn,
  .border-btn,
  .secondry-btn,
  .button-cancell {
    font-size: 14px;
    padding: 8px 13px;
  }

  .potention-wrape .container-fluid.bedges-wrape {
    max-width: 1300px;
  }

  .potention-wrape .avatar-round {
    width: 612px;
    height: 612px;
  }

  .potention-wrape .avatar-round .inner {
    width: 552px;
    height: 552px;
    padding: 40px 60px;
  }

  .potention-wrape .avatar-round .inner img {
    max-width: 100%;
  }

  .potention-wrape .avatar-round .inner p {
    font-size: var(--unit16);
    line-height: 1.5;
    width: 100%;
  }

  .potention-wrape .bedge {
    font-size: 15px;
    line-height: 1.25;
    padding: 15px 15px;
  }

  .potention-wrape .left .bedge.one {
    top: 86px;
    right: 182px;
  }

  .potention-wrape .left .bedge.two {
    top: 183px;
    right: 286px;
  }

  .potention-wrape .left .bedge.three {
    top: 280px;
    right: 328px;
  }

  .potention-wrape .left .bedge.five {
    bottom: 230px;
    right: 313px;
  }

  .potention-wrape .left .bedge.six {
    bottom: 135px;
    right: 246px;
  }

  .potention-wrape .left .bedge.four {
    bottom: 326px;
    right: 334px;
  }

  .potention-wrape .right .bedge.one {
    top: 100px;
    left: 201px;
  }

  .potention-wrape .right .bedge.two {
    top: 220px;
    left: 304px;
  }

  .potention-wrape .right .bedge.three {
    top: 350px;
    left: 335px;
  }

  .potention-wrape .right .bedge.four {
    bottom: 209px;
    left: 299px;
  }

  .potention-wrape .right .bedge.five {
    bottom: 100px;
    left: 210px;
  }

  .outer-pages .global-trade-wrape .country-wrape .country .explore_more {
    font-size: 16px;
  }

  .outer-pages .global-trade-wrape .country-wrape .country .export {
    /* right: 24px; */
    right: inherit;
  }
}

@media screen and (max-width: 1366px) {
  .global-trade-wrape .country-wrape .country {
    width: calc(100% / 6);
  }

  .contact-wrape .up-wrape {
    padding: 0 30px 10px;
  }

  .our-service-wrape .card .img-wrape .stats-wrape {
    padding: 0 30px;
  }

  .our-service-wrape .service-pad .service {
    padding: 12px 12px 12px 85px;
  }

  .our-service-wrape .service-pad .service i {
    left: 17px;
  }

  .potention-wrape .avatar-round {
    width: 552px;
    height: 552px;
  }

  .potention-wrape .avatar-round .inner {
    width: 492px;
    height: 492px;
  }

  .login-card {
    width: 38%;
  }
}

@media screen and (max-width: 1366px) {
  .potention-wrape .left .bedge.two {
    top: 177px;
    right: 266px;
  }

  .potention-wrape .left .bedge.six {
    bottom: 100px;
    right: 194px;
  }

  .potention-wrape .left .bedge.five {
    bottom: 200px;
    right: 277px;
  }

  .potention-wrape .left .bedge.three {
    top: 268px;
    right: 300px;
  }

  .potention-wrape .left .bedge.four {
    bottom: 286px;
    right: 304px;
  }

  .potention-wrape .right .bedge.two {
    top: 210px;
    left: 284px;
  }

  .potention-wrape .right .bedge.three {
    top: 320px;
    left: 305px;
  }

  .potention-wrape .right .bedge.four {
    bottom: 219px;
    left: 289px;
  }

}

@media screen and (max-width: 1280px) {
  .potention-wrape .left .bedge.two {
    top: 177px;
    right: 266px;
  }

  .potention-wrape .left .bedge.six {
    bottom: 100px;
    right: 194px;
  }

  .potention-wrape .left .bedge.five {
    bottom: 200px;
    right: 277px;
  }

  .potention-wrape .left .bedge.three {
    top: 268px;
    right: 300px;
  }

  .potention-wrape .left .bedge.four {
    bottom: 286px;
    right: 304px;
  }

  .potention-wrape .right .bedge.two {
    top: 210px;
    left: 284px;
  }

  .potention-wrape .right .bedge.three {
    top: 320px;
    left: 305px;
  }

  .potention-wrape .right .bedge.four {
    bottom: 219px;
    left: 289px;
  }

  .contact-wrape .down-wrape .form-wrape form .inquiry-form-btn {
    width: 100%;
    justify-content: flex-start;
    margin-top: 20px;
  }
}

@media screen and (max-width: 1274px) {
  .global-trade-wrape .country-wrape .country p {
    font-size: 14px;
  }

  .section-head .head p {
    font-size: 14px;
  }

  .section-head .head h2 {
    font-size: 30px;
  }

  footer .footer .img-wrape {
    position: absolute;
    opacity: 0.15;
    width: 100%;
    border: 0;
    left: 0;
    pointer-events: none;
  }

  footer .footer .content-wrape {
    width: 100%;
    border: 0;
    padding: 0;
  }

  .register-wrape.page .regi-form {
    padding: 30px 30px 30px 30px;
  }



}

@media screen and (max-width: 1199px) {
  .nav-menu li {
    border-bottom: 1px solid #e9e9e9;
    padding: 0 10px 15px 30px;
    position: relative;
  }

  .nav-menu li::before {
    background: url(../imgs/sprite.svg) no-repeat;
    content: "";
    position: absolute;
    width: 16px;
    height: 9px;
    background-position: -520px -53px;
    top: 8px;
    left: 0;
  }

  .nav-menu li.active a,
  .nav-menu li:hover a {
    color: var(--secondry-color);
  }

  .nav-menu li:hover li a {
    color: #333333;
  }

  .nav-menu li li:hover a {
    color: var(--secondry-color);
  }

  .nav-menu li.active::before,
  .nav-menu li:hover::before {
    background-position: -520px -53px;
  }

  .nav-menu li:hover li::before {
    background-position: -500px -53px;
  }

  .nav-menu li li:hover::before {
    background-position: -520px -53px;
  }

  .nav-menu li:last-child {
    border: 0;
    padding-bottom: 0;
  }

  .nav-menu li ul {
    margin-top: 15px;
    border-top: 1px solid #e0e0e0;
    padding-top: 15px;
  }

  .contact-us-wrape .contact-wrape .contact-pad .address {
    padding-right: 15px;
    width: 100%;
  }

  .container-fluid.bedges-wrape .col-lg-6 {
    width: 100%;
  }


  .potention-wrape .container-fluid.bedges-wrape {
    position: relative;
    padding-left: 50px;
  }

  .potention-wrape .container-fluid.bedges-wrape .bedge {
    position: relative;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    font-size: 16px;
    border: 0;
    width: 100%;
    border-bottom: 1px solid var(--brd1);
    border-radius: 0;
    padding-left: 0;
  }

  .potention-wrape .bedge i {
    transform: rotate(-180deg);
  }

  .potention-wrape .container-fluid.bedges-wrape .bedge i {
    right: 0;
    left: -30px;
    background-position: -400px -53px;
    width: 16px;
    height: 9px;
    transform: rotate(0);
    top: calc(50% - 4px);
  }

  .potention-wrape .bedge .dote {
    display: none;
  }

  .potention-wrape .container-fluid.bedges-wrape .bedge:hover {
    padding-left: 15px;
  }

  .offcanvas-header img {
    max-width: 180px;
  }
}

@media screen and (max-width: 1160px) {
  .section-head.center {
    max-width: 80%;
  }

  .global-trade-wrape .country-wrape .country {
    width: calc(100% / 4);
  }

  footer .footer {
    padding: 44px 15px;
  }

  footer .footer .content-wrape {
    padding-left: 0;
  }

  .main-regi {
    max-width: 80%;
    margin: 0 auto;
  }

  .contact-us-wrape .white-wrape {
    border-radius: 30px;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 1024px) {
  .inner-banner-wrape {
    height: 352px;
    padding: 0 15px;
  }

  .inner-banner-wrape .inner {
    font-size: var(--unit24);
    line-height: 1.25;
  }

  .our-service-wrape .service-pad .service .detail h5,
  .our-service-wrape .service-pad .service .detail p {
    font-size: 18px;
    line-height: 1.15;
  }

  .our-service-wrape .service-pad {
    padding: 20px 10px 10px 0;
  }

  .our-service-wrape .stats-wrape .stat .number {
    font-size: calc(var(--unit8) * 7);
  }

  .our-service-wrape .card .img-wrape .stats-wrape {
    padding: 0 20px;
  }

  .find-supplier-wrape .section-head {
    padding-right: 15px;
  }

  .find-supplier-wrape .container-fluid .container-fluid .row {
    flex-wrap: wrap-reverse;
  }

  header .brand-wrape {
    width: 120px;
    height: auto;
    background: none;
    left: 16px;
    top: 3px;
  }

  header .brand-wrape .brand {
    background: none;
    border: 0;
    box-shadow: none;
  }

  header .down-header {
    padding-left: 5px;
    width: 100%;
  }

  header .down-header .menu-wrape,
  header .down-header nav {
    border-radius: 0;
  }

  header .navbar-toggler {
    padding: 5px;
  }

  header .navbar-toggler-icon {
    width: 24px;
    height: 24px;
  }

  .banner-wrape {
    margin-top: 0;
    margin-bottom: 50px;
  }

  .banner-wrape .decoration {
    display: none;
  }

  .banner-wrape .carousel.slide,
  .banner-wrape:after {
    border-bottom-right-radius: 32px;
    border-bottom-left-radius: 32px;
  }

  header .down-header .find-btn {
    border-radius: 0;
  }

  .find-buyer-wrape .img-wrape .buble-lable {
    width: 128px;
    height: 128px;
    padding: 25px 10px 10px;
    top: 0;
  }

  .find-buyer-wrape .img-wrape .buble-lable h5 {
    font-size: 36px;
  }

  .find-buyer-wrape .img-wrape .buble-lable p {
    font-size: 14px;
  }

  .find-supplier-wrape .img-wrape .buble-lable {
    width: 146px;
    height: 146px;
  }

  .find-supplier-wrape .img-wrape .buble-lable .inner {
    width: 128px;
    height: 128px;
    padding: 20px 15px 15px;
  }

  .find-supplier-wrape .img-wrape .buble-lable h5 {
    font-size: 36px;
  }

  .find-supplier-wrape .img-wrape .buble-lable p {
    font-size: 14px;
  }

  .contact-wrape .down-wrape {
    padding: 20px 20px 0px 20px;
  }

  .contact-wrape .down-wrape .container-fluid {
    padding: 0;
  }

  .contact-wrape .down-wrape .form-wrape {
    padding: 30px 10px 30px 0px;
  }

  .contact-wrape .down-wrape .detail-part {
    padding-right: 0;
    padding-bottom: 0;
  }

  .contact-wrape .contact-pad {
    flex-wrap: wrap;
    padding: 16px 0 16px;
  }

  .contact-wrape .stats-wrape {
    margin-top: 20px;
  }

  .contact-wrape .contact-pad .address,
  .contact-wrape .contact-pad .other {
    width: 100%;
    padding-right: 0;
    margin-bottom: 15px;
  }

  .contact-wrape .contact-pad .other a {
    width: auto;
    margin-right: 30px;
    display: inline-flex;
  }

  .contact-wrape .contact-pad h6 {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .contact-wrape .contact-pad .address p {
    font-size: 14px;
  }

  .contact-wrape .up-wrape h5 {
    width: 50%;
  }

  .contact-wrape .stats-wrape .stat {
    width: 100%;
    padding: 0 0 10px 0 !important;
    border: 0;
    border-bottom: 1px solid rgba(46, 155, 240, 0.5) !important;
  }

  .contact-wrape .up-wrape {
    background-size: cover;
    height: 250px;
  }

  .contact-wrape .stats-wrape .stat .text .big {
    display: flex;
    gap: 5px;
  }

  .contact-wrape .stats-wrape .stat .number {
    font-size: calc(var(--unit8) * 7);
  }

  .contact-wrape .stats-wrape .stat:last-child {
    border-bottom: 0;
  }

  .contact-wrape .stats-wrape .stat p {
    margin-bottom: 0;
  }

  header .up-header li .form-control {
    padding: 6px 6px;
  }

  footer .decorative {
    right: 44px;
  }

  footer .decorative::before {
    width: 75%;
  }

  /* .potention-wrape .container-fluid.bedges-wrape {
    max-width: 100%;
  }

  .potention-wrape .left .bedge.two {
    top: 209px;
    right: 284px;
  }

  .potention-wrape .left .bedge.three {
    top: 320px;
    right: 304px;
  }

  .potention-wrape .left .bedge.four {
    bottom: 210px;
    right: 279px;
  }

  .potention-wrape .right .bedge.two {
    top: 220px;
    left: 290px;
  }

  .potention-wrape .right .bedge.three {
    top: 320px;
    left: 305px;
  }

  .potention-wrape .right .bedge.four {
    bottom: 203px;
    left: 285px;
  } */

  .contact-us-wrape .contact-wrape .contact-pad .other a {
    width: 70px;
  }

  .register-wrape.page {
    margin-top: 0;
  }

  .main-regi {
    justify-content: center;
  }
}

@media screen and (max-width: 1023px) {
  .offcanvas-body {
    padding: 16px 16px 75px;
  }

  .offcanvas-body .nav-link.active {
    color: var(--secondry-color);
  }

  .contact-us-wrape .map-bg {
    padding-top: 0;
  }

  .contact-us-wrape .white-wrape {
    flex-wrap: wrap;
    border-radius: 30px 30px 0 0;
    margin-bottom: 0;
    padding: 25px;
  }

  .contact-us-wrape .map-bg .form-wrape {
    padding: 15px 0 25px 0;
    margin-bottom: 30px;
    border-right: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .contact-us-wrape .detail-part {
    padding-left: 0;
  }

  .register-btn-big {
    margin: 30px 0 15px 30px;
  }

  .register-wrape {
    background-size: cover;
  }

  .our-services-page .section-service .img-wrape {
    margin-bottom: 50px;
  }

  .our-services-page .section-service.reverse:after {
    display: none;
  }

  .our-services-page .section-service.reverse:before {
    content: "";
    display: inline-block;
    width: 100%;
    background: #d0d0d0;
    height: 1px;
  }

  .sticky-footer {
    position: absolute;
    bottom: 20px;
    width: calc(100% - 40px);
    display: block;
    background: var(--white);
  }

  .sticky-footer .flag-container {
    width: 100%;
  }

  .up-header .google-translate-container {
    display: none;
  }

  .our-services-page .section-service .img-wrape .section-head,
  .benefits .img-wrape .section-head {
    display: block;
  }

  .services.page .content-wrape .section-head,
  .benefits .content-wrape .section-head {
    display: none;
  }

  header .up-header li {
    border-left: 0;
  }

  header .up-header li:last-child {
    border-left: 1px solid var(--brd1);
  }

  .find-supplier-wrape .img-wrape {
    max-width: 280px;
    justify-content: center;
    margin: 0 auto;
  }

  .find-supplier-wrape .img-wrape .buble-lable {
    left: -50px;
  }

  .find-buyer-wrape {
    margin-bottom: 120px;
  }

  .our-service-wrape .card .img-wrape .decorative::before {
    width: 63.5%;
  }

  .our-service-wrape .card .img-wrape {
    background-size: cover;
    border-radius: var(--unit32);
  }

  .our-service-wrape .service-pad {
    padding: 20px 10px 10px 10px;
  }

  .find-buyer-wrape .img-wrape img {
    width: 380px;
  }

  .find-supplier-wrape .container-fluid {
    padding: 0;
  }

  .find-supplier-wrape .container-fluid.usp-wrape {
    padding: 0 15px;
  }


  .global-trade-wrape .country-wrape .country {
    width: calc(100% / 3);
  }


  .contact-wrape .down-wrape .form-wrape {
    margin-top: 30px;
  }

  footer .decorative {
    width: calc(100% - 80px);
    right: 40px;
  }

  footer .decorative::before {
    width: 78%;
  }

  .find-buyer-wrape .img-wrape .buble-lable {
    right: inherit;
  }

  .find-supplier-wrape .container-fluid .container-fluid .row .row {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 768px) {
  .login-card {
    width: auto;
  }

  .our-service-wrape .card .img-wrape .decorative::before {
    width: 62.5%;
  }

  footer .right-section .inner-pad {
    flex-wrap: wrap;
  }

  footer .right-section .inner-pad .section-pad {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  footer .right-section .inner-pad .section-pad h6 {
    width: 100%;
    display: block;
  }

  footer .right-section .inner-pad .section-pad ul {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  footer .right-section .inner-pad .section-pad li {
    width: 50%;
  }

  footer .decorative {
    width: calc(100% - 80px);
    right: 40px;
  }

  footer .decorative::before {
    width: 76%;
  }

  .usp-wrape .usp {
    border-radius: var(--unit24);
  }

  .section-head.center {
    max-width: 100%;
  }

  .logistics-lab-wrape {
    padding: 0;
  }

  .logistics-lab-wrape .inner-pad {
    background: var(--primary-color);
    border-radius: var(--unit24);
  }

  .our-service-wrape {
    margin-top: -30px;
  }

  .section-head {
    padding-right: 20px;
  }



  .contact-wrape .up-wrape h5 {
    width: 100%;
  }



  footer .footer-bottom {
    flex-wrap: wrap;
    gap: 10px;
    padding: var(--unit16);
  }

  footer .footer-bottom span {
    width: 100%;
    text-align: center;
  }



  .usp-wrape .usp {
    padding: 20px 15px 20px 75px;
  }

  .contact-us-wrape .inner-banner-wrape {
    margin-bottom: 0;
  }

  .vision-mision-value .right-section section h2 {
    font-size: 30px;
    line-height: 1.35;
    font-weight: 600;
  }
}

@media screen and (max-width: 767px) {
  .outer-pages .global-trade-wrape .country-wrape .country .export {
    right: inherit !important;
    left: 105px;
  }

  #recaptchaDiv {
    width: 100%;
    position: relative;
    left: 0;
  }

  .g-recaptcha>div {
    width: 100% !important;
    position: relative;
    left: 0;
    top: -1px;
  }

  .g-recaptcha>div>div {
    width: 100% !important;
    transform: scale(0.73);
    text-align: center;
    position: relative;
    left: 0;
    top: -1px;
  }

  .banner-wrape button {
    height: 36px;
    width: 36px;
    border-radius: var(--unit8);
  }

  .our-service-wrape .service-pad .service .detail h5 {
    padding-right: 20px;
  }

  .contact-wrape .stats-wrape .stat .text .big {
    flex-wrap: wrap;
  }

  .contact-wrape .stats-wrape .stat {
    padding: 0 0 30px 0 !important;
    margin-bottom: 25px;
  }

  .global-trade-wrape .country-wrape .country .explore_more {
    font-size: var(--unit16) !important;
  }

  .global-trade-wrape .country-wrape .country .export {
    right: 10px !important;
  }

  .contact-us-wrape .map-bg {
    width: 100%;
    max-width: 90% !important;
  }

  .google-translate-container {
    padding: 5px 5px;
  }

  .contact-us-wrape .form-wrape {
    margin-bottom: 30px;
  }

  .contact-us-wrape .form-wrape .inquiry-form-btn {
    justify-content: flex-start;
    margin: 30px 0 0;
  }

  .inner-banner-wrape .inner {
    padding: 8px 16px;
  }

  .primary-btn,
  .border-btn,
  .button-cancell {
    padding: 5px 9px;
  }

  .login.main-regi .login-card.regi-form {
    background: var(--white);
    padding: 30px 14px 0px 10px;
  }

  .main-regi {
    background: none;
    justify-content: center;
  }

  .register-wrape.page .regi-form {
    background: none;
  }

  .register-wrape.page .regi-form form {
    background: white;
    border-radius: var(--unit16);
    padding-top: var(--unit24);
    padding-bottom: var(--unit24);
  }

  .regi-submit.fr {
    float: left;
  }

  header .up-header {
    padding: 6px 10px 6px 0;
  }

  header .up-header li {
    padding-left: 6px;
    margin-left: 6px;
  }

  header .brand-wrape {
    left: 0;
  }

  header .up-header li .form-control {
    padding: 2px 1px;
  }

  header .brand-wrape .brand img {
    max-width: 100px;
  }

  .potention-wrape .avatar-round,
  .potention-wrape .avatar-round .inner {
    border-radius: 0;
    padding: 0;
    border: 0;
    width: 100%;
    height: auto;
    margin: 0;
    box-shadow: none !important;
  }

  .potention-wrape .avatar-round {
    padding: 10px;
  }

  .potention-wrape .avatar-round .inner {
    border: 1px solid #e4e4e4;
    padding: 10px;
    border-radius: 32px;
  }

  .potention-wrape .avatar-round:after {
    display: none;
  }

  .potention-wrape .avatar-round .inner img {
    margin-bottom: 15px;
  }

  .logistics-lab-wrape .inner-pad {
    padding: 30px 10px 30px;
  }
}

@media screen and (max-width: 640px) {
  .contact-us-wrape .map-bg {
    background: url(../imgs/map-dots-bg.png) no-repeat top center;
    background-size: 180%;
  }

  .contact-us-wrape .map-bg::before {
    display: none;
  }

  .global-trade-wrape .country-wrape .country {
    width: 50%;
  }

  .our-service-wrape .card .img-wrape .decorative::before {
    width: 58.5%;
  }

  .contact-wrape .up-wrape h5 {
    font-size: 26px;
  }

  footer .right-section .inner-pad .section-pad li {
    width: 100%;
  }

  .rc-anchor-normal {
    width: auto;
    max-width: 100%;
  }

  .find-supplier-wrape .img-wrape .buble-lable {
    left: -20px;
  }

  .register-wrape .btn-wrape {
    justify-content: flex-start;
    margin-top: 5px;
  }

  .login-text.mt-2 {
    margin-top: 0 !important;
    line-height: 1;
  }
}

@media screen and (max-width: 380px) {
  .our-service-wrape .service-pad .service {
    padding: 12px 12px 12px 65px;
  }

  .our-service-wrape .service-pad .service i {
    left: 8px;
  }

  .our-service-wrape .service-pad .service .detail {
    padding: 10px 0 10px 22px;
  }

  .our-service-wrape .service-pad .service .detail h5,
  .our-service-wrape .service-pad .service .detail p {
    font-size: 15px;
  }

  .our-service-wrape .service-pad .service .export {
    width: 32px;
    height: 32px;
    right: 16px;
  }

  .global-trade-wrape .country-wrape .country {
    padding: 24px 14px;
  }

  .outer-pages .global-trade-wrape .country-wrape .country .export {
    right: inherit !important;
    left: 95px;
  }
}

.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #fff;
  color: var(--primary-color);
  font-size: 20px;
  cursor: pointer;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15);
}

.back-to-top:hover {
  background: var(--secondry-color);
  color: var(--white);
}