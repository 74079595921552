.global-trade-wrape .country-wrape .country.us i.flag {
  background-position: -6px -6px;
}

.global-trade-wrape .country-wrape .country.india i.flag {
  background-position: -6px -62px;
}

.global-trade-wrape .country-wrape .country.srilanka i.flag {
  background-position: -6px -118px;
}

.global-trade-wrape .country-wrape .country.vietnam i.flag {
  background-position: -6px -176px;
}

.global-trade-wrape .country-wrape .country.argentina i.flag {
  background-position: -5px -235px;
}

.global-trade-wrape .country-wrape .country.chile i.flag {
  background-position: -5px -299px;
}

/* .global-trade-wrape .country-wrape .country.columbia i.flag {
  background-position: -5px -360px;
} */

.global-trade-wrape .country-wrape .country.ecuador i.flag {
  background-position: -92px -6px;
}

.global-trade-wrape .country-wrape .country.mexico i.flag {
  background-position: -94px -62px;
}

.global-trade-wrape .country-wrape .country.panama i.flag {
  background-position: -93px -118px;
}

.global-trade-wrape .country-wrape .country.paraguay i.flag {
  background-position: -93px -176px;
}

.global-trade-wrape .country-wrape .country.philippines i.flag {
  background-position: -93px -235px;
}

.global-trade-wrape .country-wrape .country.uruguay i.flag {
  background-position: -93px -299px;
}

.global-trade-wrape .country-wrape .country.venezuela i.flag {
  background-position: -93px -359px;
}

.global-trade-wrape .country-wrape .country.bangladesh i.flag {
  background-position: -180px -6px;
}

.global-trade-wrape .country-wrape .country.cameroon i.flag {
  background-position: -180px -62px;
}

.global-trade-wrape .country-wrape .country.costarica i.flag {
  background-position: -180px -118px;
}

/* .global-trade-wrape .country-wrape .country.ghana i.flag {
  background-position: -6px -118px;
} */

.global-trade-wrape .country-wrape .country.kazakhstan i.flag {
  background-position: -176px -176px;
}

.global-trade-wrape .country-wrape .country.pakistan i.flag {
  background-position: -180px -235px;
}

.global-trade-wrape .country-wrape .country.tanzania i.flag {
  background-position: -179px -299px;
}

.global-trade-wrape .country-wrape .country.turkey i.flag {
  background-position: -180px -359px;
}

.global-trade-wrape .country-wrape .country .export {
  position: absolute;
  width: 36px;
  height: 36px;
  background: var(--light-primary-2);
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 50px;
  top: 50px;
  margin: 0px 5px;
}

.global-trade-wrape .country-wrape .country:hover .export {
  background: var(--primary-color);
}

.global-trade-wrape .country-wrape .country .export span {
  background-position: -323px -52px;
  width: 17px;
  height: 17px;
  display: inline-block;
}

.global-trade-wrape .country-wrape .country .explore_more {
  font-size: var(--unit24);
  text-align: left;
}

.global-trade-wrape .country-wrape .country:hover .export span {
  background-position: -303px -72px;
}

/* Registration section */

.register_section {
  position: relative;
  padding: 20px 180px;
  background: var(--primary-color);
  overflow: hidden;
  background: var(--primary-color) url("../imgs/Vector\ Smart\ Object.jpg");
  background-repeat: no-repeat;
  width: 100%;
  height: auto;
}

.register_section .regi_border {
  height: var(--unit8);
  width: 100%;
  background: var(--light-primary-2);
  position: absolute;
  top: 3px;
  left: 0;
}

.register-head {
  padding-left: 30px;
  position: relative;
  margin-bottom: 60px;
}

.register-head:before {
  content: "";
  width: var(--unit8);
  height: var(--unit8);
  position: absolute;
  left: 15px;
  top: 8px;
  border: 1px solid var(--white);
  display: inline-block;
  border-radius: calc(var(--unit8) / 2);
}

.register-head .head h3 {
  text-transform: uppercase;
  font-size: 50px;
  color: var(--white);
  margin-bottom: 5px;
  font-weight: 800;
  font-family: var(--fontFuturaBold);
}

.register-head .head p {
  font-size: var(--unit16);
  color: var(--light-primary-2);
}

.register-head .text {
  font-size: var(--unit16);
  color: var(--white);
}

.register-head .text span {
  font-size: var(--unit16);
  color: var(--light-primary-2);
  cursor: pointer;
}

.registration_button {
  border-radius: var(--unit16);
  background: var(--white);
  width: 250px;
}

.registration_button span {
  background: var(--primary-color);
}

.registration_text {
  display: flex;
  justify-content: center;
  align-items: center;
}

.registration_text h3 {
  font-size: var(--unit16);
  color: var(--black);
}

.registration_text h2 {
  font-size: var(--unit24);
  color: var(--black);
}

.registration_text .register_icon {
  background: url(../imgs/flags.png) no-repeat -292px -278px;
  width: 46px;
  height: 44px;
}

/* *****about-section******** */

.vision-mision-value .stick-section {
  position: sticky;
  top: 85px;
  display: inline-block;
  width: 30%;
}

.vision-mision-value .stick-section h3 {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #f2f2f2;
}

.vision-mision-value .stick-section a {
  position: relative;
}

.vision-mision-value .stick-section a::before {
  content: "";
  width: var(--unit8);
  height: var(--unit8);
  position: absolute;
  left: -19px;
  top: 22px;
  border: 1px solid #d4d4d4;
  display: inline-block;
  border-radius: calc(var(--unit8) / 2);
}

.vision-mision-value .stick-section a.current::before {
  content: "";
  width: var(--unit8);
  height: var(--unit8);
  position: absolute;
  left: -19px;
  top: 22px;
  border: 1px solid var(--secondry-color);
  display: inline-block;
  border-radius: calc(var(--unit8) / 2);
}

.vision-mision-value .stick-section a {
  text-decoration: none;
  font-size: 44px;
  line-height: 52px;
  color: #dfdfdf;
  font-weight: bold;
  text-transform: uppercase;
  font-family: var(--fontFuturaBold);
}

.vision-mision-value .stick-section a span {
  font-family: var(--fontFuturaBold);
}

.vision-mision-value .stick-section a.current {
  color: var(--primary-color);
}

.vision-mision-value .stick-section a.current span {
  color: var(--secondry-color);
}

.vision-mision-value .right-section {
  float: right;
  width: 65%;
}

.vision-mision-value .right-section section {
  height: 100vh;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  padding-top: 150px;
}

.vision-mision-value .right-section section h2 {
  margin-bottom: 50px;
  font-weight: 500;
  max-width: 80%;
}

.vision-mision-value .right-section #section-1 {
  align-items: start;
  height: auto;
  padding-top: 0px;
}

.vision-mision-value .right-section #section-1 p {
  padding-bottom: 20px;
}

.vision-mision-value .right-section section:last-child {
  /* padding-bottom: 50%; */
}

.vision-mision-value .right-section h4 {
  font-size: 36px;
  line-height: 50px;
  color: #353535;
  font-weight: 600;
  margin-bottom: 16px;
  width: 100%;
}

.vision-mision-value .right-section p {
  font-size: var(--unit16);
  line-height: 26px;
  color: #353535;
  width: 100%;
}

.sticky-section-wrape {
  display: table;
}

.find-buyer-wrape,
.find-supplier-wrape,
.our-achievement-wrape,
.vision-mision-value,
.inner-banner-wrape,
.register-wrape {
  margin-bottom: 100px;
}

/* best fact style start */

.our-achievement-wrape {
  display: flex;
}

.best-fact {
  background: var(--primary-color) url("../imgs/achivvmentImage.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  border-radius: var(--unit32);
  padding: 50px;
  width: 100%;
  float: left;
}

.best-fact .up-wrape {
  float: left;
  width: 100%;
}

.best-fact .up-wrape .text {
  width: 46%;
  float: left;
  margin-bottom: 80px;
}

.best-fact .up-wrape .text h2 {
  font-size: 28px;
  line-height: 35px;
  color: var(--white);
  font-weight: bold;
}

.best-fact .up-wrape .text p {
  font-size: 16px;
  line-height: 26px;
  color: var(--white);
}

.best-fact .up-wrape .stat {
  padding-top: 0px;
  width: 52%;
  display: flex;
  justify-content: end;
  align-items: center;
  padding-bottom: 185px;
  float: right;
  margin-right: 0;
}

.best-fact .up-wrape .stat h6 {
  font-size: 72px;
  line-height: 52px;
  color: var(--light-primary-2);
  font-weight: bold;
  width: 100px;
}

.best-fact .up-wrape .stat::before {
  top: 88px;
  bottom: 0;
  height: 200px;
  left: 66.5%;
}

.best-fact .up-wrape .stat::after {
  top: inherit;
  bottom: -9px;
  left: 65%;
}

.best-fact .bottom-stat {
  width: 95%;
  border-top: 1px dashed var(--light-primary-2);
  float: left;
  padding-left: 100px;
}

.best-fact .stat {
  padding-top: 130px;
  position: relative;
  float: left;
  width: 180px;
  margin-right: 50px;
}

.best-fact .stat::before {
  width: 1px;
  height: 113px;
  border-left: 1px dashed var(--light-primary-2);
  position: absolute;
  top: 0;
  left: 30px;
}

.best-fact .stat::after {
  width: 18px;
  height: 18px;
  position: absolute;
  top: -9px;
  left: 21px;
  background: var(--secondry-color);
  border: 4px solid var(--white);
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}

.best-fact .stat h6 {
  font-size: 72px;
  line-height: 52px;
  color: var(--light-primary);
  font-weight: 700;
  margin-bottom: 10px;
}

.best-fact .stat p {
  font-size: var(--unit24);
  line-height: 28px;
  color: var(--white);
  font-weight: bold;
  text-transform: uppercase;
}

.normal-bold {
  font-weight: 500;
}

.best-fact .stat .number-text {
  font-size: var(--unit16);
  color: #b2c9da;
  font-weight: 500;
}

.achive-contry-text {
  color: var(--white);
  font-size: var(--unit16);
}


.after-before,
header nav li .service-sub .box a:hover:before,
.best-fact .stat::before,
.best-fact .stat::after {
  content: "";
  display: inline-block;
}

.after-before,
header nav li .service-sub .box a:hover:before,
.best-fact .stat::before,
.best-fact .stat::after {
  content: "";
  display: inline-block;
}

.about-us-page-wrape .find-buyer-wrape .img-wrape {
  text-align: end;
}

/* *****footer ***** */

.about-us-page-wrape .footer-effect .decorative,
.our-services-page .footer-effect .decorative,
.contact-us-wrape .footer-effect .decorative {
  display: none;
}

.inner-banner-wrape.about-us {
  background: url(../imgs/about_us_Banner.png) no-repeat;
}

.inner-banner-wrape {
  background-size: cover;
}

/* ***find-buyer-supplier-data*******/
.buyer-suplier-search-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px 0px 25px 30px !important;
}

.buyer-suplier-section-child {
  width: 26%;
}

.buyer-suplier-section-child.search-btn {
  width: 18%;
  padding-top: 30px;
}

.find-buyer-supplier-wrape .search-section,
.find-buyer-supplier-wrape .buyer-suplier-data-table {
  box-shadow: 5px 8px 24px rgba(0, 0, 0, 0.1);
  border-radius: var(--unit16);
  margin-bottom: 30px;
}

.find-buyer-supplier-wrape .search-section {
  /* padding: 16px 0px 16px  30px; */
  padding: 16px;
}

.find-buyer-supplier-wrape .search-section .primary-btn,
.find-buyer-supplier-wrape .search-section .invalid-feedback {
  position: relative;
}

.find-buyer-supplier-wrape .buyer-supplier-product-inpute {
  padding-right: 50px;
}

.find-buyer-supplier-wrape .search-section .primary-btn {
  font-size: 16px;
  width: 113px;
}

.find-buyer-supplier-wrape .section-head {
  margin-bottom: 0;
  text-align: center;
}

.find-buyer-supplier-wrape .section-head::before {
  display: none;
}

.buyer-suplier-data-table-header {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  border-radius: var(--unit16);

  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.buyer-supplier-card {
  width: 100%;
  display: flex;
  border-bottom: 1px solid var(--white);
  align-items: center;
}

.buyer-supplier-card div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0px;
  font-size: var(--unit16);
  text-align: center;
  height: 100%;
}

.buyer-supplier-card .sr-no {
  /* width: 5%; */
  width: 100px;
}

.buyer-supplier-card .hs-code {
  /* width: 14%; */
  width: 220px;
}

.buyer-supplier-card .company-name {
  /* width: 28%; */
  width: 450px;
}

.buyer-supplier-card .country {
  /* width: 20%; */
  width: 300px;
}

.buyer-supplier-card .trade-type {
  /* width: 15%; */
  width: 250px;
}

.buyer-supplier-card .status {
  /* width: 10%; */
  width: 200px;
}

.buyer-supplier-card .action {
  /* width: 8%; */
  width: 100px;
}

.buyer-supplier-card .header-border {
  border-right: 1px solid #e7e7e7;
}

.buyer-supplier-card .header-border.action .fa-eye:hover {
  cursor: pointer !important;
}


.buyer-supplier-card.card-header {
  background-color: var(--primary-color);
  border-radius: 16px 16px 0px 0px;
}

.buyer-supplier-card.card-data {
  background: #d4d4d4;
}

.buyer-supplier-card.card-data-color {
  background: #f6f6f6;
}

.buyer-supplier-card.card-header div {
  font-weight: 500;
  color: var(--white);
}

.buyer-supplier-btn {
  display: flex;
  justify-content: center;
  padding: 70px 0px 40px 0px;
}

/* ****reset**btn***Ui */

.buyer-supplier-btn .supplier:hover {
  background: none;
  border: 1px solid #8b8b8b;
  color: #8b8b8b;
}

.buy-suply-reset-button:hover {
  background: none !important;
  border: 1px solid #8b8b8b !important;
  color: #8b8b8b !important;
}

.buyer-supplier-btn button {
  font-size: 16px;
}

.find-buyer-supplier-wrape .buyer,
.find-buyer-supplier-wrape .supplier,
.find-buyer-supplier-wrape .buyser-supplier-search,
.registor-now-btn,
.find-view-more-btn a,
.buy-suply-reset-button {
  font-weight: 700;
}

.find-buyer-supplier-wrape .buyer:hover {
  background: var(--primary-color);
  color: var(--white);
}

.find-buyer-supplier-wrape .supplier:hover {
  background: #8b8b8b;
  color: var(--white);
}

.buyer-supplier-btn .supplier,
.deactive-buyer {
  background: #8b8b8b;
}

.active-supplier {
  background: var(--primary-color);
}

.find-buyer-supplier .banner-wrape {
  margin-bottom: 80px;
}

.find-buyer-supplier-wrape .form-label {
  font-size: 16px !important;
}

.find-view-more-btn {
  padding: 40px 0px;
  text-align: center;
}

.card-data .header-border {
  font-size: var(--unit16);
}

.buyer-supplier-moredata-text {
  color: var(--primary-color);
}

.find-buyer-supplier-wrape .section-head .head p {
  position: relative;
  display: inline;
}

.find-buyer-supplier-wrape .section-head .head p::before {
  content: "";
  width: var(--unit8);
  height: var(--unit8);
  position: absolute;
  left: -15px;
  top: 5px;
  border: 1px solid var(--secondry-color);
  display: inline-block;
  border-radius: calc(var(--unit8) / 2);
}

/* *****registration-page****/

.registration-capch-inner div iframe {
  height: 80px;
}

/* registration-popup-box */

.registration-modal-body {
  border: none;
}

.registration-modal-contant .modal-dialog .modal-content {
  border-radius: var(--unit32);
  border: none;

  /* --bs-modal-width:600px !important; */
}

.registration-modal-contant .modal-dialog {
  max-width: 600px;
}

.registration-modal-header {
  display: flex;
  justify-content: center;
  background: var(--primary-color);
  border-top-left-radius: var(--unit24);
  border-top-right-radius: var(--unit24);
  padding: 35px 0px;
}

.registor-now {
  padding-bottom: 15px;
}

.registor-now-text {
  padding: 15px 0px;
  border-bottom: 1px solid #d4d4d4;
  border-top: 1px solid #d4d4d4;
}

.registration-modal-footer {
  display: flex;
  justify-content: center;
  padding: 0px 0px 15px;
}

.registor-arrow-down {
  position: absolute;
  top: 28%;
  left: 42%;
  transform: translate(-50%, -50%);
  width: 0;
  height: 0;
  margin: 29px;
  border-style: solid;
  border-width: 10px 0 10px 20px;
  clip-path: polygon(100% 50%, 0 100%, 0 0);
  color: var(--primary-color);
  transform: rotate(90deg);
}

.buy-suply-reset-button {
  background: #8b8b8b !important;
}

a.btn.primary-btn.active-suppliersupplier:hover {
  background: var(--primary-color);
  color: var(--white);
}

a.btn.primary-btn.buyer.deactive-buyer {
  background: #8b8b8b;
  color: var(--white);
}

a.btn.primary-btn.buyer.deactive-buyer:hover {
  border: 1px solid #8b8b8b;
}

.buyer-supplier-btn a.btn.primary-btn.active-suppliersupplier {
  font-weight: 700;
}

.buyer-supplier-btn a.btn.primary-btn.buyer:focus,
.buyer-supplier-btn a.btn.primary-btn.active-suppliersupplier:focus {
  color: var(--white);
}

/**buyer-supplier-loader***/

.buyer-supplier-loader {
  width: 50px;
  padding: 5px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: var(--primary-color);
  --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;
}

/* *****btn-deler****** */


.btn-delete-popup
{
  background-color:var(--secondry-color);
border: 1px solid var(--secondry-color);
border-radius: var(--unit8);


}

.btn-delete-popup:hover
{
background-color: var(--white);
border: 1px solid var(--secondry-color);
color:var(--secondry-color);

}

@keyframes l3 {
  to {
    transform: rotate(1turn);
  }
}

/* ****google-translae-header****** */


.google-translate-header div .Drop-down-menu__menu {
  width: auto;
}

/* ****responsive css******* */

@media screen and (max-width: 1800px) {
  .buyer-suplier-section-child.search-btn {
    width: 19%;
  }
}


@media screen and (max-width: 1680px) {
  .global-trade-wrape .country-wrape .country .explore_more {
    font-size: 20px;
  }

  .global-trade-wrape .country-wrape .country .export {
    right: 30px;
  }

  .buyer-suplier-section-child {
    width: 24%;
  }

  .buyer-suplier-section-child.search-btn {
    width: 20%;
  }

}

@media screen and (max-width:1440px) {
  .buyer-suplier-section-child.search-btn {
    width: 25%;
  }

}

@media screen and (max-width:1200px) {
  .buyer-suplier-section-child {
    width: 45% !important;
    margin: 5px 0px;
    margin-right: 30px;
  }

}


@media screen and (max-width: 1024px) {
  .best-fact .stat {
    margin: 0 !important;
    line-height: 20px;
  }

  .vision-mision-value .stick-section a {
    font-size: var(--unit32);
    line-height: 0;
  }

  .vision-mision-value .stick-section h3 {
    margin-bottom: 10px;
    padding-bottom: 10px;
  }

  .best-fact .bottom-stat,
  .best-fact .stat::before {
    border: none;
  }

  .best-fact .up-wrape .stat {
    width: 100%;
    padding-top: 140px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    padding-bottom: 48px;
    float: right;
    margin-right: 0;
    padding-left: 100px;
    border-left: 1px dotted var(--light-primary-2);
  }

  .best-fact .up-wrape .text {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: start;
    text-align: start;
  }

  .best-fact .up-wrape .stat h6,
  .best-fact .up-wrape .stat .countries100 {
    width: 100%;
  }

  .buyer-supplier-card {
    width: auto;
  }

  /* border***css** */

  .best-fact .bottom-stat {
    border: none;
    width: 100%;
    border-left: 1px dashed var(--light-primary-2);
    float: left;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }

  .best-fact .up-wrape .stat::before {
    width: 34%;
    top: 88px;
    bottom: 0;
    height: 200px;
    left: 0;
  }

  .best-fact .stat {
    padding: 50px 0px;
  }

  .best-fact .up-wrape .stat::after {
    left: 32%;
    bottom: 71.5%;
  }

  .best-fact .stat::before {
    width: 66%;
    border-top: 1px dotted var(--light-primary-2);
    left: -56%;
  }

  .about-us-page-wrape .find-buyer-wrape .img-wrape {
    text-align: left;
  }

  .best-fact .up-wrape .stat {
    padding-top: 50px;
  }

  .best-fact .up-wrape .stat::before {
    top: 0;
  }

  .best-fact .up-wrape .stat::after {
    bottom: 97%;
  }

  /* ****style*/


}

@media screen and (max-width: 767px) {
  .best-fact {
    padding: 40px 30px;
  }

  .about-us-page-wrape .vision-mision-value {
    padding-left: 30px;
  }

  footer .footer .content-wrape li a {
    padding-right: 10px;
  }

  footer .footer .content-wrape .social-media a {
    padding: 10px 15px;
  }

  .vision-mision-value .stick-section {
    display: none;
  }

  .vision-mision-value .right-section {
    width: 100%;
  }

  .vision-mision-value .stick-section.d-none {
    position: static;
    display: block !important;
    width: 100%;
  }

  .vision-mision-value .right-section section {
    height: auto;
  }

  .vision-mision-value .right-section section:last-child {
    padding-bottom: 0;
  }

  .registration-modal-contant .modal-dialog .modal-content {
    margin: 0px 20px;
  }


  /* ****style** */

  .buyer-suplier-section-child {
    width: 100% !important;

  }

  .buyer-suplier-section-child.search-btn {
    padding-top: 10px;

  }
}
