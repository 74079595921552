 * {
     margin: 0;
     padding: 0;
     box-sizing: border-box;
     font-family: "Public Sans";
 }

 /* **** login, reset, forgot, 404 error, maintenance ************* */

 .login {
     display: flex;
     flex-wrap: wrap;
     align-items: center;
 }

 .login-check {
     border: 2px solid #dee2e6;
     cursor: pointer;
 }

 .rs-input:focus {
     outline: none !important;
     box-shadow: 0px 0px 0px 0px !important;
 }

 .logindesboard_img {

     display: flex;
     flex-wrap: wrap;
     justify-content: center;
     width: 60%;
     background-color: #f2f2f9;
 }

 .login-card {
     width: 32%;
     padding: 20px 10px 20px 20px;
     margin: auto;
 }


 .login-icon {
     width: 25px;
     height: 25px;
     margin: 0px 5px;
     margin-bottom: 8px;
 }

 input::placeholder {
     color: #d4d4d4 !important;
     opacity: 1;
     /* Firefox */
 }

 input::-ms-input-placeholder {
     /* Edge 12 -18 */
     color: #d4d4d4 !important;
 }

 .logindesboard_img img {

     height: 100vh;
     width: inherit;
     object-fit: contain;
 }


 .resetpassword-img img {

     object-fit: contain;

 }

 .login-btn {
     background-color: #0c4572 !important;
     color: #ffffff !important;
 }

 .login-btn:hover {
     background-color: #032c4c !important;
 }




 .card-forgot,
 .card-reset {
     padding-bottom: 55px !important;
 }

 .login-text {
     cursor: pointer;
     color: #0b4471;
 }

 .login-title {
     color: #323d4b;
     font-size: 24px;
 }

 .login-subtitle {
     color: #a0a6ac;
 }


 .form-control:focus {
     border: none;
 }

 .form-control {
     font-size: 14px !important;
     color: #212529;
 }

 .form-control:disabled,
 input[disabled] {
     background-color: #f9f9f9;
     color: #d4d4d4;
     cursor: default !important;
 }

 .disable-upload-img {
     background-color: #f2f2f2;
     cursor: default !important;

 }


 .loginpage-img {
     display: flex;
     justify-content: center;

 }


 .loginpage-img img {
     width: 200px;
     height: 90px;
 }

 .fa-eye,
 .fa-question {
     cursor: pointer;
 }

 .fa-question {
     line-height: 15px;

 }

 .fa-question::before {
     font-size: 9px;
     padding: 1px;
     color: #0B4471;
     border-radius: 100px;
 }

 .tooltip-btn {
     border-radius: 100px;
     height: 15 px;
     display: flex;
     justify-content: center;
     width: 15px;
     position: absolute;
     top: -70%;
     right: -20%;
 }


 /* tooltip****animated */






 /********ERROR******/

 .error {

     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
     background-color: #f5f5f9;
     height: 100vh;
     padding: 50px 0px;

 }

 .error-img {
     display: flex;
     justify-content: center;
     margin-top: 80px;
 }

 .error-img img {
     width: 90%;
     object-fit: contain;



 }

 .error404_logo {
     background-color: #f5f5f9;

 }

 .error404_logo img {
     position: absolute;
     left: 20%;
     width: 230px;
     height: 110px;
 }

 .erro-text {
     padding-top: 30px;
     text-align: center;
 }

 .erro-text h1 {
     margin: 0;
     font-size: 42px;
     color: #0b4471;
     font-weight: 600;
 }


 .erro-text p {
     font-size: 22px;
     font-weight: 400;
     padding-top: 0;
     margin: 0;
 }

 .error-reason p {
     font-size: 16px;
     line-height: 25px;
     font-weight: 400;
     color: #263238;

 }

 .error-reason {
     text-align: center;
     padding-top: 20px;
 }

 .error-reason h6 {

     font-size: 21px;
     font-weight: 700;
     color: #000000;
 }

 .error_500 {
     margin-top: 50px;
 }

 .error_500 p {

     font-size: 18px;
     color: #000000;
     font-weight: 400;
     line-height: 20px;
 }

 .back_home_404 {
     width: 200px;
     text-align: center;
     height: 50px;
     font-weight: bold;
     font-size: 17px;

 }

 /* *******maintenace_section****** */

 .maintenance {
     background-color: #f5f5f9;
     height: 100vh;
 }

 .maintenance_section {
     display: flex;
     flex-wrap: wrap;
     justify-content: center;
     align-items: center;


 }

 .maintenace_Text {
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
     height: 90vh;
     width: 40%;


 }

 .maintenece_img {

     width: 45%;
     background-color: #f5f5f9;

 }

 .maintenance .m_logos img {
     position: absolute;
     left: 15.4%;
 }

 .maintenace_Text .d-flex {
     padding-top: 90px;
 }


 .maintenece_img img {
     width: 80%;
     padding: 50px 0px;
     object-fit: cover;
 }

 .loginpage-img img {
     width: 180px;
     height: 90px;
 }

 .maintenance_logo img {
     height: 140px;
     width: 65%;
 }



 .maintenace_Text h1 {
     margin: 0;
     padding: 0;
     font-size: 90px;
     color: #df0024;
     font-weight: 700;
 }

 .maintenace_Text h2 {
     margin: 0;
     padding: 0;
     font-size: 45px;
     color: #0b4471;
     font-weight: 700;
     margin-top: 10px;
 }

 .maintenace_Text p {
     font-size: 18px;
     color: #000000;
     font-weight: 400;
     line-height: 23px;
     margin-top: 15px;
 }

 .maintenace_Text h5 {
     font-size: 21px;
     color: #000000;
     margin-top: 15px;
     font-weight: 700;
 }

 /** Button CSS Start **/
 .btn-add {
     padding: 8px 10px;
     margin: 5px;
     border: 1px solid #0c4572;
     background-color: #ffffff;
     border-radius: 4px;
     color: #0c4572;
 }

 .btn-add:hover {
     color: #ffffff;
     background-color: #0c4572;
 }

 .btn-regi {
    border: 1px solid #0c4572;
     border-radius: 4px;
     font-size: 14px;
     padding: 8.5px 12px;
     font-weight: 600;
     background-color: #0c4572;
     color: #ffffff;
     border: none;
 }

 .reject-color {
     background-color: #ff6700;
 }

 .reject-color:hover {
     background-color: #c6611e;
 }

 .suspend-color {
     background-color: #dc3545;
 }

 .suspend-color:hover {
     background-color: #af3441;
 }

 .approve-color {
     background-color: #28a745;
 }

 .approve-color:hover {
     background-color: #1c8635;
 }

 .btn-remove {
     padding: 5px 10px;
     margin: 5px;
     border: 1px solid #0c4572;
     background-color: #ffffff;
     border-radius: 4px;
     color: #df0024;
     border: 1px solid #df0024;
 }

 .btn-remove:hover {
     background-color: #df0024;
     color: white;
     transition: .33s;

 }

 /** Button CSS End **/

 .FileuploadSection .invalid-feedback {
     position: relative;
 }

 /* *****form layour *** */

 .form-control:focus {
     border: 1px solid #3498ff !important;
     box-shadow: 0px 0px;
 }

 .css-t3ipsp-control {
     box-shadow: 0px 0px 0px 0px !important;
 }

 .chek-style {
     background-color: #0b4471 !important;
 }


 .form-check-input {
     color: #0b4471 !important;
     cursor: pointer;
 }

 .rs-slider-progress-bar {
     background-color: #0b4471 !important;
 }


 .css-13cymwt-control {
     height: 10px !important;

 }

 .Drop-down-menu__control,
 .creatable_react_select__control {
     cursor: pointer !important;
     border: 1px solid #dee2e6 !important;

 }

 .creatable_react_select__option--is-selected {
     background-color: #4c9bdb !important;
 }

 .form-size {
     font-size: 14px;
     height: 38px !important;
     /* border: 1px solid red; */
 }
up

 .form-error .css-13cymwt-control {
     /* border: 1px solid red; */
     /* border-radius: 5px; */
     /* border: 1px solid red !important; */
 }

 .form-error .rs-picker-input-group {
     /* border: 1px solid red !important; */
 }

 .form-error .dropdown-container {
     /* border: 1px solid red !important; */
 }

 .form-error-textarea .rs-input {
     /* border: 1px solid red !important; */
 }


 /* ****comman-class in form feild **** */

 /* .form-error
 {
    border: 1px solid red;
    border-radius: 5px  !important;
 } */

 .form-text-error {
     font-size: 13px;
     display: none;
     color: red;
 }

 .search-dropdown {
     width: 27%;
 }

 .css-tj5bde-Svg {
     color: #a0a6ac;
 }


 .rs-picker-daterange-calendar-group {
     display: flex !important;
 }


 .rs-btn {
     background-color: #0b4471 !important;
     color: white !important;
 }

 .rs-date-range-input {
     outline: none !important;
 }

 .rs-picker-input-group {
     outline: none !important;

 }

 .dropdown-container:focus-within {
     box-shadow: 0px 0px 0px !important;
 }

 .rs-calendar-table-cell-content:hover {
     background-color: #0b4471 !important;
     color: white !important;
 }

 .rs-calendar-time-dropdown-cell-active {
     background-color: #0b4471 !important;
 }

 .rs-picker-daterange-header {
     display: none !important;
 }


 .rs-checkbox-control {
     display: none !important;
 }

 .rs-picker-value-item,
 .rs-checkbox-label {
     color: black !important;
 }


 .rs-picker-value-count {
     background-color: #0b4471 !important;
 }



 /* ********multiselect ****** */

 .input-container {
     display: flex;

 }

 .create-option .material-icons-outlined {
     color: #0b4471;
     vertical-align: text-bottom;
     font-size: 18px;
 }

 .all-variants {
     display: flex;
     flex-direction: column;
 }




 /* ******table ******** */

 .AcSimpleTable {

     width: 100%;

 }

 .data-table-main {
     margin: 0px 25px;
 }

 .no-data-found {
     font-size: 14px;
     line-height: 34px;
     color: #000000;
     font-weight: 600;
 }


 .hDPlLz {
     font-size: 14px !important;
     color: #000000 !important;
     font-weight: 400;
     border-bottom-left-radius: 8px;
     border-bottom-right-radius: 8px;

 }

 .card-shadow {
     filter: drop-shadow(0 0 4px #d4d4d4);

 }

 .tbl-text-wrap-row {
     white-space: pre !important;
     overflow: hidden;
     text-overflow: ellipsis;
 }


 /* ***table-header-color */


 .table-header-name {
     display: flex;
     flex-wrap: wrap;
     justify-content: space-between;
     align-items: center;
     margin: 0px 10px;
 }

 .caNzNe {
     border-top: 1px solid #d4d4d4;
 }


 .hZZlND {
     border-radius: 0px !important;
 }

 .table-text {
     font-size: 23px;
     color: #000000;
     font-weight: 400;
 }

 /* **table-data-text** */

 .rdt_TableCell div {
     font-size: 14px;
     line-height: 24px;
     color: #000000;
     font-weight: 400;
 }

 /* .action-icon-btn {
     margin: 0px 1px;
 } */

 /* **table-header-text** */

 .rdt_TableCol_Sortable div {
    color: #000000;
    font-size: 14px;
    font-weight: 650;
    text-wrap: wrap;
}

 .icon-trash {
     color: #df0024;
 }

 .icon-pen,
 .icon-lock,
 .icon-eyes,
 .icon-plan {
     color: #0c4572;
 }


 /* Orex-content Area */


 .bg-white.p-2.topmenut-nav {
     border: none !important;
     box-shadow: rgba(0, 0, 0, 0.1) 0px 6px 15px;
     /* border-bottom-left-radius:5px; */
 }

 .fa-bars {
     color: #0b4471;
     cursor: pointer;

 }

 li.dropdown-item span {
     color: #000000;
     cursor: pointer;
     font-size: 14px;
 }

 .dropdown-item:active {
     background: #0B4471;
 }


 .profile-name {
     font-weight: 700;
 }

 .subscription-plan {
     color: #979797 !important;
 }


 /* ******alter-Popobox******* */

 .modal {
     --bs-modal-footer-border-width: 0px !important;
     --bs-modal-header-border-width: 0px !important
 }

 .modal-title {
     color: #000000;
     font-size: 28px;
 }


 .modal-large .modal-content {
     height: 90vh !important;
 }

 .dialog-cancel {
     border-radius: 6px;
     background-color: #ebebeb;
     color: #0b4471;
     font-weight: 700;
     font-size: 14px;
     border: none;
 }

 .dialog-create {
     border-radius: 6px;
     background-color: #0b4471;
     color: #ffffff;
     font-weight: 700;
     font-size: 14px;
     border: none;
 }

 .dialog-create:hover,
 .dialog-cancel:hover {
     background-color: #3c97df;
 }


 .modal {
     position: fixed;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     background: rgba(0, 0, 0, 0.6);
 }

 .modal-header .btn-close {
     border: 1px solid;
     border-radius: 100px;
     font-size: 6px;
     font-weight: bolder;
     margin-bottom: 20px;

 }


 .modal-body {
     border-top: 1px solid #d4d4d4;
     border-bottom: 1px solid #d4d4d4;
     margin: 0px 15px;
 }

 .popup-input-lable {
     color: #010101;
     font-size: 14px;
     font-weight: 500;
     margin-bottom: 8px;
 }


 .modal-main {
     position: fixed;
     background: white;
     width: 80%;
     height: auto;
     top: 50%;
     left: 50%;
     transform: translate(-50%, -50%);
 }

 .display-block {
     display: block;
 }

 .display-none {
     display: none;
 }

 .btn-assign
 {
    /* border-radius: 4px; */
    /* font-size: 14px; */
    /* padding: 8.5px 12px; */
    font-weight: 600;
    background-color: #0c4572 !important;
    color: #ffffff !important;
    border: none !important;
 }



 .Drop-down-menu__option--is-selected {
     background: #4c9bdb !important;
     cursor: pointer;
 }

 .react-select-dropdown__control {
     cursor: pointer !important;
 }

 .react-select-dropdown__option--is-selected {
     background-color: #2b6491 !important;
 }

 .react-select__multi-value__label {
     padding-left: 3px !important;
 }

 .react-select__multi-value--is-disabled,
 .creatable_react_select__multi-value--is-disabled {
     background-color: #f9f9f9 !important;
 }

 .react-select__multi-value--is-disabled div,
 .creatable_react_select__multi-value--is-disabled div {
     color: #d4d4d4;
 }

 .form-select:focus {
     border: 1px solid #3498ff;
     box-shadow: none;
 }

 .react-select__control {
     box-shadow: none !important;
 }


 /* ***** other info ******/


 /* *******Services***** */

 input[type=checkbox] {
     width: 17px !important;
     height: 17px !important;
 }






 /* *****upload img **** */

 .multi-upload {
     position: relative;
     border-radius: 10px !important;
     border: none;

 }


 .multi-upload button {
     position: absolute;
     top: 5%;
     right: 5%;
     height: 26px;
     width: 27px;
     cursor: pointer;
     background-color: white;
     border-radius: 50%;
     border: 1px solid #dadada;

 }

 .multi-upload button:hover {
     background-color: #df0024;
 }

 .multi-upload button:hover .fa-trash-can {
     color: white !important;
 }



 .dropzone-files {
     height: 38px;
     display: flex;
     justify-content: space-between;
     align-items: center;
     border: 1px solid #d4d4d4;
     border-radius: 5px;
     cursor: pointer;


 }

 .upload-certificate {
     color: #979797;
     font-size: 13px;
     /* padding-left: 10px; */
 }

 .certificate-img-hover {
     cursor: pointer;
 }

 .certificate-img-hover-none {
     cursor: default;
 }


 .choose {

     display: flex;
     align-items: center;
     height: 38px;
     padding: 0px 20px;
     color: #000000;
     background-color: #f3f3f3;
     border-radius: 0px 5px 5px 0px;
     border: 1px solid #d4d4d4;

 }


 .img-upload {
     display: flex;
     justify-content: start;
     align-items: center;
     /* padding: 10px; */
 }

 .upload_img {

     cursor: pointer;
     border: 1px solid #ebebee;
     padding: 4px;

 }



 .fa-pen-main {
     position: relative;

 }

 .fa-pen {
     position: absolute;
     bottom: 10%;
     right: 8%;
     cursor: pointer;
     background: #0B4471;
     padding: 9px;
     color: white;
     font-size: 14px;
     border-radius: 50%;
     border: 3px solid #FFFFFF
 }

 .reset-btn {
     background: #FFEEF1;
     border: 1px solid #DF0024;
     color: #df0024;
     padding: 4px 20px;
     border-radius: 6px;
     /* width: ; */

 }


 /* *********company-profile-****** */


 .company-main {
     margin: 0px 25px;
     /* padding-bottom: 25px; */

 }

 .company-main h4 {
     color: #0b4471 !important;
 }

 .company-details-text {

     font-size: 18px;
     background-color: #ffffff;
     color: #0b4471;
     font-weight: 700;
     padding: 10px 20px;
     border-bottom: 1px solid #ebebee;
     border-top-left-radius: 8px;
     border-top-right-radius: 8px;

 }

 .not-veryfied {
     display: flex;
     flex-wrap: wrap;
     justify-content: space-between;

 }

 .about-company {
     /* padding: 40px 20px; */
     background-color: #fff;
     border-bottom-left-radius: 8px;
     border-bottom-right-radius: 8px;
 }

 .about-company-details {
     margin-left: 20px;
 }

 .company-other-details {
     padding: 2px;
     padding-left: 20px;
     padding-right: 20px;
 }

 .Hsn_main {

     border-top: 1px solid #d4d4d4;
     padding-top: 20px;
     padding-bottom: 20px;
 }


 .Hsn_text_section {
     display: flex;
     justify-content: start;
     /* align-items: center; */
     padding: 4px 0px;
 }

 .Hsn_text1 {
     width: 10%;
 }

 .Hsn_badge {
     width: 75%;
 }

 .company-info-icon {
     width: 23px;
     height: 23px;
 }

 .company-info-icon-img-video {

     height: 25px;
     margin-right: 4px;
     margin-bottom: 9px;


 }

 .company-info-main {
     display: flex;
     flex-wrap: wrap;
     justify-content: space-between;
     background-color: #fff;
     border-bottom-left-radius: 8px;
     border-bottom-right-radius: 8px;
     padding-left: 25px;

 }

 .company-card-info {

     width: 46%;
 }

 .company-card-service,
 .company-card-apve {
     /* width: 30%; */
     width: 25.5%;
 }

 .company-card h4 {
     padding: 10px 0px;
     font-size: 18px;
     color: #000000;
     font-weight: 700;
 }

 .company-card .company-service-inf {
     padding: 10px 0px;
 }

 .company-card .company-service-inf p {
     font-size: 14px;
     line-height: 34px;
     color: #000000;
     font-weight: 600;
     margin: 0;
 }

 .company-card div p i {
     color: #0B4471;
     font-size: 15px;
     margin-right: 8px;
 }

 .company-pro-text {
     font-size: 23px;
     color: #000000;
     padding-bottom: 3px;

 }

 .company-menagement-text {
     font-size: 12px;
     color: #0b4471;
     font-weight: 400;
     cursor: pointer;
 }

 .company-profile-text {
     font-size: 12px;
     color: #979797;
     font-weight: 400;
 }

 .company-profile-tabs {
     display: flex;
     justify-content: space-between;
     align-items: center;
     flex-wrap: wrap;
     padding: 5px 0px;

 }

 .company-info-logo {
     width: 100%;
     display: flex;
     flex-wrap: wrap;
     justify-content: start;
     justify-content: space-between;
     margin-bottom: 20px;
 }


 .company-info-logo.mt-5.row {
     align-items: center;

 }


 .company-prifile-logo {
     width: 200px;
     padding-right: 0;
     text-align: start;
 }

 .company-prifile-logo .profile-imges,
 .viewprofile-img-effect {
     width: 160px;
     overflow: hidden;
     transition: 0.7s;
 }

 .viewprofile-img-effect .profile-imges:hover {
     transform: scale(1.2);
     transition: 0.7s;
 }

 .fa-pen:hover {
     transform: scale(1.1);
 }



 .NFT_Roadway {
     width: calc(100% - 200px);
 }

 .NFT_Roadway h3 {
     margin: 0;
     font-size: 24px;
     color: #000000;
     font-weight: 700;
     line-height: 1.75rem;
 }

 .NFT_Roadway span {
     font-size: 16px;
     color: #979797;
     font-weight: 400;
 }

 .NFT_Roadway h6 {
     font-size: 16px;
     color: #000000;
     font-weight: 600;
 }

 .NFT-company-name {
     height: 80px;
 }


 .company-video {
     border-bottom-left-radius: 8px;
     border-bottom-right-radius: 8px;
     padding: 20px 0px;
 }

 .company-pro-details {
     margin-top: 25px;
     filter: drop-shadow(0 0 4px #d4d4d4);
 }

 .company-info-details {
     filter: drop-shadow(0 0 4px #d4d4d4);
 }

 .content .container-fluid {
     padding-left: calc(var(--bs-gutter-x)) !important;
     padding-right: calc(var(--bs-gutter-x)) !important;
 }

 .content .container-fluid .row .tab-menu {
     padding: 0;
     margin: 0;
 }


 .tab-menu-child {
     display: flex;
     flex-wrap: wrap;
     justify-content: space-between;
     padding: 0px 7px;
     padding-bottom: 18px;


 }


 .company-approve-btn div {
     display: flex;
     justify-content: end;
     flex-wrap: wrap;
     align-items: center;
     margin-bottom: 7px;
 }

 .sub-header span {

     font-size: 13px;
     font-weight: 400;
     line-height: 14.1px;
     color: #3c97df;
     cursor: pointer;

 }

 .sub-header .in-active-breadcrumb {
     color: #979797;
 }

 .sub-header .active-breadcrumb {
     color: #3c97df;
 }


 /* row******8 */


 .company-roadway-detials {
     font-size: 14px;
     line-height: 25px;
     color: #979797;
     font-weight: 400;
 }

 .company-roadway-detials-info {

     font-size: 14px;
     line-height: 25px;
     color: #000000;
     font-weight: 600;

 }

 .badge {
     background-color: #2b6491;
     font-size: 14px;
     font-weight: bolder;
     cursor: help;
 }

 .badge-cursor {
     cursor: default;
 }

 .tab-review {
     background-color: #ff6700;
     width: 100px;
     padding: 8px 0px;
 }

 .ompany-socialmedia-main {
     margin-top: 15px;
     margin-left: 8px;
 }

 .company-socialmedia img {
     height: 35px;
     padding: 3px;

 }

 .PhoneInputCountrySelect {
     /* width: 111%;
     margin-left: -2px; */
     width: 141%;
     margin-left: 0px;
     border: 1px solid #d4d4d4;
     border-right: 0;
     border-top-left-radius: 6px;
     border-bottom-left-radius: 6px;
     margin-left: -2px;
 }

 .PhoneInputCountrySelect[disabled] {
     background: #f9f9f9;
     color: #d4d4d4;
 }

 /* .css-olqui2-singleValue
 {
    color: #d4d4d4;
 } */
 .PhoneInputCountryIcon svg {
     background: #f2f2f2;
 }

 .PhoneInputCountryIcon svg path {
     color: #f2f2f2 !important;
     fill: #f2f2f2 !important;
 }

 .phone_disable,
 .phone_disable input {
     opacity: 1;
     pointer-events: none;
     background-color: #f9f9f9;
     color: #d4d4d4;
     cursor: default !important;
 }


 /* row **** */

 .contect-company {
     display: flex;
     flex-wrap: wrap;
     justify-content: space-between;

 }

 .contect-info-compnay-main {

     width: 49%;
     /* border: 1px solid #ebebee; */
     border-radius: 8px;
 }

 .contect-info-compnay-main h3 {
     font-size: 18px;
     background-color: #fff;
     color: #0b4471;
     font-weight: 700;
     padding: 10px 20px;
     border-bottom: 1px solid #ebebee;
     border-top-left-radius: 8px;
     border-top-right-radius: 8px;

 }

 .company-Representative {

     border: 1px solid #ebebee;
     border-radius: 8px;
     width: 49%;
 }



 .view-contact-info {
     width: 100%;
     border: 1px solid #ebebee;
     border-radius: 8px;
 }

 .view-profile-contact {
     width: 120px;
 }

 .view-profile-contact-details {
     width: calc(100% - 120px);
 }


 .view-profile-contact-img {
     height: 110px;
     width: 110px;
     text-align: center;
     line-height: 110px;
     border-radius: 100px;
     border: 1px solid #d4d4d4;
 }

 .view-profile-contact-img .img {
     width: inherit;
     height: inherit;
     border-radius: 100px;
     object-fit: cover;

 }


 .FileuploadSection .file-title {
     font-size: 15px;
     font-weight: 600;
     color: #000000;
     padding: 0px 10px;

 }

 .company-representive-border .PhoneInputCountry,
 .contact-select-border .PhoneInputCountry {
     border: none;
 }

 .company-Representative h4,
 .view-contact-info h4 {

     font-size: 16px;
     color: #000000;
     font-weight: 700;
     padding: 5px 12px;
     background-color: #fff;
     border-bottom: 1px solid #ebebee;
     border-top-left-radius: 8px;
     border-top-right-radius: 8px;

 }

 .contactUploadImg {
     width: 100px;
 }

 .UpdateCompanyImage {
     margin: 10px 0px;
     gap: 20px;
 }

 .companyInfoImagSection {
     display: flex;
     flex-wrap: wrap;
     gap: 24px
 }



 .UpdateCompanyImage .companyContactInfo {
    position: relative;
     background-color: #f5f5f9;
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
     width: 210px;
     padding-top: 20px;
     text-align: center;

 }


 .contect-info-child.row {
     --bs-gutter-x: 0 !important;

 }

 .company-Representative-name {
     font-size: 14px;
     color: #979797;
     font-weight: 400;

 }

 .company-Representative-info {
     font-size: 14px;
     color: #000000;
     font-weight: 600;
     overflow-wrap: break-word;
 }


 .company-roadway-detials-info a,
 .company-Representative-info a {
     color: #3498ff;
     font-size: 14px;
     display: block;
     font-weight: 600;
     text-decoration: none !important;
     overflow-wrap: break-word;
 }

 .company-Representative-info a:hover {
     color: #3498ff;
 }

 .company-roadway-detials-info a:hover {
     color: #3498ff !important;
 }


 /* ====>  company video lnk  */

 .custom-class-name {
     display: flex;
     flex-wrap: wrap;
     margin-left: 10px;
     padding: 10px 0px;
     /* padding-bottom: 8px; */
 }

 .custom-class-name a {
     cursor: pointer;

 }


 .company-images-main {
     /* height: 145px; */
     display: flex;
     justify-content: center;
     align-items: center;
     width: 285px;
     margin: 10px;
     border: 1px solid #d4d4d4;
     border-radius: 10px;
     overflow: hidden;
     /* box-shadow: 0px 2px 10px #0b4471; */
 }

 .company-images-main:hover {
     filter: drop-shadow(1px 1px 6px #010101);
     transition: 0.5s;
 }

 .custom-class-name a img {
     /* border-radius: 10px; */
     /* height: 145px;
     width: 100%; */
     transition: 0.4s;
     width: 285px;
     height: 200px;

     /* object-fit: cover; */
 }

 .custom-class-name a img:hover {
     transform: scale(1.2);
     transition: 0.4s;
 }




 .lg-backdrop.in {
     opacity: 0.8;
 }



 /* ****compney info*** */

 .nav-link:hover {
     color: black;
 }

 .company-info-text {
     font-size: 23px;
     color: #000000;
     font-weight: 500;
     margin: 10px 0px;
     display: none;
     /* margin: 0px 11px; */

 }

 .profile-text {

     color: #010101;
     font-size: 14px;
     font-weight: 500;
     line-height: 25px;
     text-align: left;

 }

 .profile-btn {
     text-align: right;
 }



 .company-required,
 .company-required-content {
     color: #df0024;
     font-size: 13px;
     font-weight: 600;
     display: none;
 }

 .certificate-required {
     color: #df0024;
     font-size: 13px;
     font-weight: 600;
 }

 .head-office-text {
     font-size: 22px;
     color: #000000;
     font-weight: 500;
 }

 input[type="checkbox"] {

     accent-color: #2b6491;
     cursor: pointer;
 }

 .input-group-addon {
     height: 38px;
     background-color: #fff !important;
     border-top-right-radius: 0 !important;
     border-bottom-right-radius: 0 !important;
     border-right: 0 !important;
 }

 .update-profile-wrapper .input-group-addon,
 .contact-info-wrapper .input-group-addon,
 .reference-wrapper .input-group-addon {
     padding: 8px 12px;
     font-size: 14px;
     font-weight: 400;
     line-height: 1;
     color: #555;
     text-align: center;
     background-color: #eee;
     border: 1px solid #dee2e6;
     border-radius: 3px;
     white-space: nowrap;
     vertical-align: middle;
 }

 .input-group-disable {
     background: #f9f9f9 !important;
 }

 .input-group-addon span {
     background: #fff;
 }

 .input-group-addon select {
     border: none;
     background-color: white;
     cursor: pointer;
 }

 .user-managment-cng-paswrd
 {
    right: 18px !important;
    bottom: 30px !important;
 }


 select:focus-visible {
     /* border: 1px solid white; */
     border-radius: 3px;
     outline: none;
 }

 .mr-input {
     border-bottom-left-radius: 0 !important;
     border-top-left-radius: 0 !important;
     cursor: pointer;

 }

 .preview-profile-btn {
     font-size: 15px;
     font-weight: 600;
     padding: 6.25px 12px;
     /* border: 1px solid #d4d4d4 !important; */
     margin-bottom: 2px;
 }

 .drop-down-disable div {
     background-color: #f9f9f9;
 }

 .profile-suggest-btn {
     width: 200px;
     filter: drop-shadow(0px 5px 2px #d4d4d4);

 }

 .profile-suggest-btn p {
     font-size: 16px;
     padding: 13px 0px;
 }

 /* .drop-down-disable-city div
 {
    background-color: #f9f9f9;
 } */


 .drop-down-disable-multi .css-4gg2os-control .css-1dyz3mf .css-m2x7k5 {
     display: none;
 }

 .css-qr46ko div {
     padding: 5px 10px;
     cursor: pointer;
 }




 /* prorile-rejected ui */


 .rejected-reason {
     color: #000000;

 }

 .rejected-reason span {
     font-weight: 600;
     /* color: #979797; */

 }

 /* .pro-reject{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top:50px;
    text-align: center;
   
    
 }

 .pro-reject-content {
    border: 1px solid #d4d4d4;
    width: 250px;


 } */





 /* ***** other info ******/

 .Other-info h4 {
     font-size: 22px;
     color: #000000;
     font-weight: 500;
     padding-bottom: 10px;
     border-bottom: 1px solid #d4d4d4;

 }

 .other-info-title {
     font-size: 14px;
     color: #010101;
     font-weight: 500;
 }

 .Other-info .text-danger {
     font-size: 14px;
 }

 button.ck.ck-button.ck-off svg path {
     fill: #010101 !important;

 }

 .ck-sticky-panel__content {
     width: 30% !important;
 }

 .PhoneInputInput {
     height: 38px;
     border: 1px solid #dee2e6;
     border-top-right-radius: 5px;
     border-bottom-right-radius: 5px;
     border-left: 0;
     padding-left: 15px;
 }

 #contact-form-phone:focus,
 #contact-form-mobile:focus,
 #contact-form-phone:active,
 #contact-form-mobile:active {
     /* border: 1px solid #4A8EFF !important; */
     /* border: 1px solid white !important; */
 }

 .PhoneInputCountry {

     margin: 0;
     border-top-left-radius: 5px;
     border-bottom-left-radius: 5px;
     border: 1px solid #d4d4d4;
     padding: 4px 5px;
     border-right: 0;
 }

 .outer-pages .PhoneInputInput:focus-visible {
     border: none;
     outline: none;
 }

 .fa-trash-can {
     cursor: pointer;
     font-size: 16px !important;
 }

 /* #contact-form-mobile:focus
 {
border: 1px solid red;
 } */

 .ck-powered-by {
     display: none;
 }

 /* ****Compnay info***** */

 .css-1xc3v61-indicatorContainer svg path {
     fill: #d4d4d4 !important;
 }

 .css-1u9des2-indicatorSeparator {
     width: 0 !important;
 }


 .form-check-input:checked {
     background-color: #0b4471;
     border-color: #0b4471;
 }

 /* ********services***** */


 .servies-main-section {
     display: flex;
     flex-wrap: wrap;
     /* border-bottom: 1px solid #d4d4d4; */
     padding: 15px 0px;
 }

 .services-child {
     display: flex;
     width: 25%;
     margin: 10px 0px;
     /* border: 1px solid; */
 }

 .services-child label,
 .other-service-lable {
     font-size: 14px;
     color: #010101;
     font-weight: 400;
 }

 .services-read-more-text {
     color: #3498ff;
     font-size: 13px;
     text-align: right;
     margin-right: 21%;
     cursor: pointer;
 }

 /** Screen loader - Start **/
 .pos-main {
     text-align: center !important;
     line-height: 100vh !important;
     vertical-align: middle !important;
 }

 .pos-center {
     position: fixed;
     width: 100%;
     height: 100%;
     top: 50%;
     left: 0%;
     right: 0;
     bottom: 50%;
     background-color: #fffc;
     background: rgba(255, 255, 255, 0.6);

     z-index: 10000;
     cursor: pointer;
     margin: auto;
     /* box-shadow: 0 0 0 3px #1c59de33; */
     border-radius: 8px;
 }


 /* .loader {
     border: 4px solid #f3f3f3;
     border-top: 4px solid #0b4471;
     border-radius: 50%;
     width: 30px;
     height: 30px;
     animation: spin 1s linear infinite;
     top: 50%;
     left: 50%;
     position: fixed;
 } */



 /** Screen loader - End **/



 /* ******certificate******* */


 .certificate-main {
     display: flex;
     flex-wrap: wrap;
     /* justify-content: center;  */
     /* margin-top: 10px; */
     /* width: 100%; */
 }

 .certificate-child-first {
     margin: 20px 0px;
     width: 25%;
 }

 .certificate-child-secound {
     display: flex;
     align-items: center;
     /* width: 25%; */
 }


 .cerificate-text {
     font-size: 14px;
     color: #010101;
     font-weight: 550;
 }

 .upload-doc-text {
     font-size: 24px;
     color: #000000;
     font-weight: 500;
     padding: 10px 0px;
     border-bottom: 1px solid #d4d4d4;

 }

 .certificate-item {
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
     border: 1px solid #d4d4d4;
     margin: 6px;
     height: auto;
     width: 110px;
     padding: 5px;
 }

 .certificate-item img {
     height: 100px !important;
     width: 100px !important;
 }

 .upload-doc {
     padding-bottom: 10px;
 }

 .other-certificate-main {
     padding: 30px 0px;
 }

 .upload-text {
     font-size: 14px;
     color: #010101;
     font-weight: 500;
     padding-bottom: 5px;
 }

 .upload-main {
     display: flex;
     flex-wrap: wrap;
     justify-content: space-between;
     /* margin-top: 30px; */
     padding: 30px 0px;
 }

 .upload-content {
     display: flex;
     /* justify-content: center; */
     align-items: center;
     gap: 10px;
     width: 50%;

 }

 .upload-back {
     padding-left: 30px;

 }


 .upload-input {
     width: 70%;
 }

 .upload-img {
     display: flex;
     flex-direction: column;
     justify-content: center;
     position: relative;
     top: 28px;
     align-items: center;
     border-radius: 6px;
     width: 20%;
     text-align: center;
 }

 .contact-upload-image {
     top: 0;
     width: auto;
 }

 .upload-img img {
     height: 100px !important;
     width: 100px !important;
     object-fit: contain;
     border: 1px solid #d4d4d4 !important;
 }

 .pdf-close {
     position: absolute;
     top: 4%;
     right: 5.8%;
     border: 1px solid #d4d4d4;
     background-color: white;
     /* color: #3c97df; */
     width: 24px;
     height: 24px;
     border-radius: 12px;
 }

 .pdf-close:hover {
     background-color: #df0024;

 }


 .d-flex.align-items-center .fa-trash-can:hover {
     background-color: #df0024;
     color: white !important;
 }


 .pdf-close:hover .fa-trash-can {
     color: #fff !important;

 }


 .choose-pdf-error {
     /* border: 1px solid #df0024; */
 }

 .upload-img-error {
     /* border: 1px solid #df0024; */
 }

 .upload-info {
     margin-top: 5px;
     font-size: 14px;
     color: #979797;
     font-weight: 400;
     /* text-align: c; */
 }


 .upload-faild-text {

     font-size: 13px;
     color: #3c97df;
     font-weight: 400;

 }

 /** No Data Found Image **/

 .no-data-found {
     display: block;
     margin-left: auto;
     margin-right: auto;
     height: 180px;
 }

 .no-data-text-color {
     color: #979797;

 }

 .no-data-content {
     padding: 40px 20px;
 }

 .Toastify {
     z-index: 99;
 }



 .copyright-text {
     font-size: 14px;
     line-height: 34px;
     color: #979797;
     font-weight: 400;
     padding-left: 30px;
     margin: 8px 0px;
 }

 .AcSimpleTable .card1 {
     filter: drop-shadow(0 0 4px #d4d4d4);
     background-color: #fff;
     border-radius: 8px;
 }


 .certificate-main p a {

     color: #3498ff;
 }

 .certificate-main p a:hover {
     text-decoration: none
 }




 /* **********shipment-details *********8 */

 .shipment-details {
     margin: 0px 25px;
 }

 @keyframes spin {
     0% {
         transform: rotate(0deg);
     }

     100% {
         transform: rotate(360deg);
     }
 }

 .prev-custom-button,
 .next-custom-button {
     display: flex;
     justify-content: center;
     align-items: center;
     background: white;
     cursor: pointer;
     border: none;
     min-width: 43px;
     min-height: 43px;
     outline: none;
     border-radius: 35px;
     color: #d4d4d4;
     border: 1px solid #d4d4d4;
 }

 .prev-custom-button:hover,
 .next-custom-button:hover {
     background: #0b4471;
     border: none;
     color: white;
 }


 /* ***********Registration-Success Massge***** */


 .registration-success {
     display: flex;
     justify-content: center;
     align-items: center;
     width: 100%;
     background-color: #fff;
     min-height: 100vh;

 }

 .successContent {
     width: 100%;
     text-align: center;
 }

 .successContent h2 {
     font-size: 40px;
     color: #0b4471;
 }

 .successContent p {
     font-size: 16px;
     margin: 20px 0px;

 }

 /** Filter css **/
 .filter-search-btn {
     display: flex;
     align-items: center;

 }

 .filter-search-btn svg {
     height: 15px;
     margin: 0px 2px;
 }

 .filter-reset-btn {
     /* color: #0c4572; */
     background: #f9fafa;
     /* font-weight: 400; */
     font-size: 14px;
     padding: 7px 12px;
     border: 1px solid #d4d5d8;
 }

 .filter-reset-btn:hover {
     /* cursor: pointer; */
     border: 1px solid #d4d5d8;

 }


 .filter-reset-text {
     color: #3c97df;
     font-size: 14px;
     margin: 0px 5px;
     cursor: pointer;
     /* font-weight:500; */

 }

 .filter-reset-text:hover {
     /* color:#df0024 ; */
     text-decoration: underline;

 }

 .ReactTags__selected {
     display: none;
 }

 .selected-custom-tags {
     display: flex;
     flex-wrap: wrap;
     justify-content: start;
     padding: 0px 40px 10px;
 }

 .selected-custom-tags div {
     display: flex;
     justify-content: center;
     align-items: center;
     padding: 0px 0px 0px 3px;
     border-radius: 2px;
     color: #fff;
     font-size: 14px;
 }

 .selected-custom-tags-child {
     background-color: #4c9bdb;
     padding: 2px 0px;
     margin: 2px;

 }


 .selected-custom-tag-button {
     height: inherit;
     line-height: inherit;
     width: 22px;
     background-color: #4c9bdb;
 }

 .selected-custom-tag-text {
     padding: 2px;
 }

 .selected-custom-tag-button:hover {
     background-color: #df0024;
     cursor: pointer;
 }


 .selected-custom-tags div i {

     font-size: 9px;
     font-weight: bolder;
     text-align: center;
     height: inherit;
     line-height: 25px;
     font-weight: 999;

 }

 .selected-custom-tag-text:hover {
     cursor: pointer;
 }


 /* ********manage-div-height******** */

 .outer {
     height: auto
 }

 #portfolio {
     /* border:1px solid red; */
     height: 200px;
     margin: 1.5em;
     overflow: hidden;
 }

 .readmore {
     height: auto !important;
 }



 /* ********manage-div-height-end******** */


 .ReactTags__suggestions {
     position: absolute;
     z-index: 1;
     width: 50%;


     ul {
         list-style-type: none;
         box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
         background: white;
         width: 200px;
         padding: 5px 0;
         width: 63%;
         height: 300px;
         overflow-y: scroll;
     }

     li {
         padding: 5px 10px;
         margin: 0;
         text-align: left;

         mark {
             text-decoration: underline;
             background: none;
         }

         &.ReactTags__activeSuggestion {
             background: #4c9bdb;
             color: #fff;
             cursor: pointer;

             span mark {
                 color: #fff;
             }
         }
     }

 }

 .ReactTags__clearAll {
     position: absolute;
     left: 0;
     top: 0;
     visibility: hidden;
 }

 .filter-section {
     position: relative;
 }

 .item-selected {
     /* background-color: #2684FF; */
     color: white;
 }

 .item-selected:hover {
     /* background-color: #2684FF; */
     color: #000000;
 }

 .item-selected2 {
     /* background-color: green; */
 }

 /* .member-filter-select__indicators .member-filter-select__indicator {
     padding: 0;

 } */

 .member-filter-select__control {
     cursor: pointer !important;
     box-shadow: none !important;
 }

 .member-filter-select__multi-value__label {
     font-size: 14px !important;
 }


 .member-filter-select__multi-value div svg {
     color: #fff;
 }

 .member-filter-select__menu-list div {
     margin: 2px 0px;

 }

 .member-filter-select__menu-list div:hover {
     background-color: #DEEBFF;
     cursor: pointer;

 }

 .buyer-suplier-search-section.member-filter
 {
    padding: 26px 0px 20px 10px !important;
 }


 .table-wrapper-inner  .action-icon-btn:active
 {
    border: none;

 }

 .div-item-selected {
     background: #4c9bdb;
 }

 .div-item-selected:hover {
     background: #4c9bdb !important;
     color: #fff;

 }

 .div-item-selected:hover .item-selected {
     background: #4c9bdb !important;
     color: #fff;
 }

 /* ****hsCodeInputField ****** */

 .hsCodeInputField[type=number]::-webkit-inner-spin-button,
 .hsCodeInputField[type=number]::-webkit-outer-spin-button {
     -webkit-appearance: none;
     -moz-appearance: none;
     appearance: none;
     margin: 0;
 }



 /* *****dashboart-chart ********* */

 .dashboart-chart {
     background: #f5f5f9;
     margin: 25px;

 }

 .dashboart-chart h4 {
     font-size: 23px;
     font-weight: 700;
 }

 .dashboard-row {
     display: flex;
     flex-wrap: wrap;
     justify-content: space-between;
 }

 .dashboard-box {
     width: 49%;
     background-color: #fff;
     height: 450px;
     row-gap: 10px;
     margin-top: 30px;
     border-radius: 8px;
     box-shadow: rgba(0, 0, 0, 0.1) 0px 6px 15px;
 }

 .center-mapc-chart {
     padding-right: 80px;
 }

 .dashboard-box-map {
     width: 100%;
     height: 600px;
     margin: 30px 0px;
     background-color: #fff;
     border-radius: 8px;
     padding: 10px;

 }




 .dashbord-welcom-text {

     border-radius: 8px;
     /* padding: 15px; */
     /* background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 6px 15px; */

 }

 .dashbord-welcom-text p {
     font-size: 16px;
     font-weight: 500;

 }

 .dashbord-welcom-text p span {
     font-size: 21px;
     font-weight: 700;

 }



 /* ****responsive media********* */

 @media(max-width:1700px) {

     .company-approve-btn {
         width: 100%;
         text-align: end;
     }

     .hendle-aprove-btn {
         width: 100%;
         text-align: end;
         margin: 10px 0px;

     }


 }


 @media(max-width:1600px) {

     .company-info-main {
         justify-content: flex-start;
     }


     .company-card-service,
     .company-card-apve {
         width: 27%;
     }


     .Hsn_text1 {
         width: 20%;
     }

     .upload-content {
         width: 98%;
     }

     .upload-back {
         padding-left: 0;

     }

     .mobile-view-back {
         margin-top: 50px;
     }

     .mobile-view-border {
         border-right: 0px !important;
     }
     


 }


 @media(max-width:1500px) {


     /* .login-card {

         width: 30%;

     } */

     .maintenance .m_logos img {
         position: absolute;
         left: 10%;
     }

     .company-Representative {
         width: 90%;
         margin: 0px 10px;
     }



     .contect-info-child.row.justify-content-between.mt-3 {
         display: flex !important;
         justify-content: center !important;
     }


     .company-card-service,
     .company-card-apve {
         width: 33%;
     }

     .hendle-aprove-btn {
         width: 100%;
         text-align: end;
         margin-bottom: 10px;
     }

     .NFT-company-name {
         height: auto;
     }

     .company-info-logo {

         /* justify-content: start;
     gap: 20px;
      */

     }

     .company-card-info {
         width: 60%;
     }

     /* ***Dashbord -ui *** */
     .dashboard-box {
         width: 100%;
     }

     .contactUploadImg {
         width: 80px;
     }


 }


 @media(max-width:1350px) {


     .contect-info-compnay-main {
         width: 100%;
     }

     .company-Representative {
         width: 47%;
         margin: 0px 10px;
     }

     .services-child {
         width: 33%;
     }


     .contect-info-child.row.justify-content-between.mt-3 {
         justify-content: start !important;
     }

     .selected-custom-tags {
        padding: 0px 20px 10px;
    }
    



 }

 @media(max-width:1300px) {

     .profile-tab {
         display: none;
     }

     .company-approve-btn {
         width: auto;

     }

     .hendle-aprove-btn {
         width: auto;
     }

     .login {
         justify-content: center;
     }

     .login-card {
         width: 60%;
         margin-top: 30px;
         padding: 0;

     }

     .logindesboard_img {
         width: 60%;
         margin-top: 20px;
     }

     .logindesboard_img img {
         width: inherit;
         height: auto;

     }

     .upload-content {
         width: 98%;
     }

     .upload-back {
         padding-left: 0;

     }



     .company-card h4 {
         margin-top: 30px;
     }

     .company-card-info {
         width: 80%;

     }

     .company-card-service,
     .company-card-apve {
         width: 48%;

     }

     .company-info-text {
         display: block;
     }

     .tab-menu-child {
         border-bottom: 1px solid #d4d4d4;
         padding-bottom: 10px;
     }


 }

 @media(max-width:1200px)
 {
    .buyer-suplier-search-section.member-filter
    {
       justify-content: flex-start;
    }

    .buyer-suplier-search-section.member-filter.filter-search-btn
    {
        width: 49%;
    }

 }
 

 @media(max-width:1150px) {



     .contect-info-compnay-main {
         width: 100%;
     }


     .lg-outer .lg-object {
         max-height: 60%;
     }


     .Hsn_text1 {
         width: 35%;
     }


     .company-Representative {
         width: 90%;
         margin: 0px 10px;
     }

     .contect-info-child.row.justify-content-between.mt-3 {
         justify-content: center !important;
     }

     .certificate-child-first {
         margin: 20px 0px;
         width: 50%;
     }

     .services-child {
         width: 50%;
     }


     .not-veryfied-logo {
         text-align: end;
     }


     .not-veryfied-logo .not-veryfied-img {
         width: 50%;

     }

     /* .successContent
     {
        width: 80%;
     } */

     .registration-success {
         width: 100%;
     }



 }



 @media(max-width:1000px) {

     .maintenece_img {
         display: flex;
         justify-content: center;
         width: 100%;
     }

     .maintenece_img img {
         padding-top: 20px;
     }

     .maintenace_Text {
         text-align: center;
     }

     .error404_logo img {

         left: 50%;
         transform: translate(-50%);
     }

     .maintenance .m_logos img {
         left: 50%;
         transform: translate(-50%);
     }


     .maintenace_Text {
         height: 70vh;
     }

     .company-socialmedia .d-flex {
         justify-content: center;
     }

     .company-card-service,
     .company-card-apve {
         width: 100%;

     }

     .company-card-info {
         width: 100%;
     }

     .not-veryfied-logo {
         width: 100%;
     }

     .not-veryfied-logo .not-veryfied-img {
         width: 40%;

     }




 }

 @media(max-width:800px) {


     .login-card {
         width: 90%;

     }

     .logindesboard_img {
         width: 90%;
     }

     .erro-text,
     .error-reason {
         padding: 20px;
     }

     .company-Representative {
         width: 90%;
     }


     .company-prifile-logo {
         width: 100%;
         text-align: center;
     }

     .company-info-logo {
         justify-content: start;
     }

     .company-menagement-text {

         padding-bottom: 15px
     }

     .Hsn_text1 {
         width: 50%;
     }

     .upload-img {
         width: 25%;
     }

     .services-child {
         width: 100%;
     }

     .NFT_Roadway {
         width: 100%;
     }




 }

 @media(max-width:500px) {

     .maintenace_Text h1 {
         font-size: 70px;
     }

     .maintenace_Text h2 {
         font-size: 35px;
     }

     .logindesboard_img {
         width: 90%;
     }

     .login-card {
         width: 90%;

     }

     .error_500 h1 {
         font-size: 35px;
     }

     .error-img {

         margin-top: 50px;
     }

     .company-main {
         margin: 0px 30px;

     }

 }

 @media(max-width:400px) {

     .login-title {
         font-size: 20px;
     }

     .erro-text,
     .error-reason {
         padding: 0px 10px;
         margin-top: 10px;
     }

 }